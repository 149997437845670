import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userStorage from "../../common/userStorage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import userImage from "../../images/user.png";
import "./ImageGallery.css";
import "../search/Search.css";
import web3Service from "../../common/web3Service";
import _ from "lodash";

const ImageGallery = ({
  setSelectedItem,
  setNumberOfGoat,
  setOwnerWallet,
}: any) => {
  //Set Data
  const [getGallaryData, setGetGallaryData] = useState([]);
  const [searchData, setSearchData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const authInfo = userStorage.getAuthInfo();
  const axiosPrivate = useAxiosPrivate();
  //Load Data
  useEffect(() => {
    const api = authInfo?.isAdmin ? "GetAllUsers" : "GetAllPublicUsers";
    async function fetchData() {
      const response = await axiosPrivate.get(api);
      setGetGallaryData(response?.data);
      setNumberOfGoat(response?.data?.length || 0);
    }
    fetchData();
  }, []);
  //Set modal data in state
  const modalData = async (selectedItem: any) => {
    setSelectedItem(selectedItem);
    if (!!selectedItem?.tokenId || selectedItem?.tokenId === 0) {
      const ownerWallet = await web3Service.ownerOf(selectedItem?.tokenId);
      setOwnerWallet(ownerWallet);
    } else {
      setOwnerWallet(null);
    }
  };

  const handleSearchTextChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setSearchData((values: any) => ({ ...values, [name]: value }));
  };

  const searchProfile = async (event: any) => {
    event.preventDefault();
    // if (!searchData?.search) {
    //   return;
    // }
    const api = authInfo?.isAdmin ? "GetAllUsers" : "GetAllPublicUsers";
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${api}?search=${searchData?.search || ""}`
      );
      setGetGallaryData(response?.data);
      setNumberOfGoat(response?.data?.length || 0);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const search = (event: any) => {
    if (event.key === "Enter") {
      searchProfile(event);
    }
  };

  const navigate = useNavigate();
  return (
    <div className={isLoading ? "disabled register-main" : "register-main"}>
      <section className="gallary">
        <div className="main-gallery">
          <div className="search">
            <input
              type="text"
              name="search"
              onChange={handleSearchTextChange}
              onKeyDown={search}
              placeholder="Search"
            />
            <i
              className="fa-solid fa-magnifying-glass"
              onClick={searchProfile}
            ></i>
          </div>
          <div className="gallery-wrapper">
            {(getGallaryData || []).map((singleGallaryData: any) => {
              const lockerNumber = !!singleGallaryData?.lockerNumber
                ? `LOCKER #${singleGallaryData?.lockerNumber}`
                : "";
              return (
                <div
                  className="single-gallary-item"
                  key={singleGallaryData?.id}
                >
                  <div
                    onClick={(event) => {
                      modalData(singleGallaryData);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="modal-open-area"
                  >
                    {singleGallaryData?.nftImage && (
                      <img
                        src={`${singleGallaryData?.nftThumbnail}?${Date.now()}`}
                        alt="gallary-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `${singleGallaryData?.fallbackImageThumbnail}`;
                        }}
                      />
                    )}
                  </div>
                  <div className="info">
                    <h5>{lockerNumber}</h5>

                    <div className="user-info-wrapper">
                      {singleGallaryData?.cognitoUser?.picture ? (
                        <img
                          src={`${
                            singleGallaryData?.cognitoUser?.picture
                          }?${Date.now()}`}
                          alt=""
                        ></img>
                      ) : (
                        <img src={userImage} alt=""></img>
                      )}
                      <span className="name-edit-wrapper">
                        <div className="profile-name">
                          {singleGallaryData?.cognitoUser?.name}{" "}
                          {singleGallaryData?.cognitoUser?.familyName}{" "}
                        </div>
                        {authInfo?.isAdmin && (
                          <i
                            className="fas fa-edit user-edit-btn"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              navigate(`/update/${singleGallaryData?.id}`);
                            }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImageGallery;
