import { useLocation, Navigate, Outlet } from "react-router-dom";
import userStorage from "../common/userStorage";

const RequireAuth = ({ allowedRoles }: any) => {
  const authInfo = userStorage.getAuthInfo();
  const location = useLocation();

  return (!!allowedRoles &&
    authInfo?.roles?.find((role: any) => allowedRoles?.includes(role))) ||
    (!allowedRoles && authInfo?.idToken) ? (
    <Outlet />
  ) : !!allowedRoles && authInfo?.idToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
