import goatStorage from "./goatStorage";
const AUTH_KEY = "goat-auth-info";
const USER_KEY = "goat-user-info";
const userStorage = {
  getAuthInfo() {
    return goatStorage.getJson(AUTH_KEY) || {};
  },
  saveAuthInfo(authInfo: any, roles: any) {
    const isAdmin = !!roles?.find((role: any) => role === "SuperAdmin");
    goatStorage.setJson(AUTH_KEY, {
      ...authInfo,
      roles: roles,
      isAdmin: isAdmin,
    });
  },
  updateAuthToken(authInfo: any) {
    const auth = this.getAuthInfo();
    goatStorage.setJson(AUTH_KEY, {
      ...authInfo,
      roles: auth?.roles,
      isAdmin: auth?.isAdmin,
    });
  },
  getUserInfo() {
    return goatStorage.getJson(USER_KEY) || {};
  },
  saveUserInfo(userInfo: any) {
    goatStorage.setJson(USER_KEY, { ...userInfo });
  },
  revoveAllInfo() {
    goatStorage.clear(AUTH_KEY);
    goatStorage.clear(USER_KEY);
  },
};

export default userStorage;
