import React, { useState } from "react";
import { Banner } from "../banner/Banner";
import ImageGallery from "../imageGallery/ImageGallery";
import ImageModalDetails from "../imageModalDetails/ImageModalDetails";
import MiddleContent from "../middleContent/MiddleContent";
import "./Home.css";

const Home = () => {
  //Modal Data form single gallery item
  const [selectedItem, setSelectedItem] = useState(null);
  const [numberOfGoat, setNumberOfGoat] = useState(null);
  const [ownerWallet, setOwnerWallet] = useState(null);

  return (
    <main>
      <div className="home-page">
        <Banner />
        <MiddleContent numberOfGoat={numberOfGoat} />
        <ImageGallery
          setSelectedItem={setSelectedItem}
          setNumberOfGoat={setNumberOfGoat}
          setOwnerWallet={setOwnerWallet}
        />
        <ImageModalDetails
          goatProfile={selectedItem}
          ownerWallet={ownerWallet}
        />
      </div>
    </main>
  );
};

export default Home;
