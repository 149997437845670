import { css } from "styled-components";

export const BaseFontFamily = css`
  font-family: "Raleway", sans-serif;
`;

export const Heading1 = css`
  ${BaseFontFamily}
  font-weight: 200;

  font-size: 80px;
  line-height: 88px;

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Heading2 = css`
  ${BaseFontFamily}
  font-weight: 300;

  font-size: 68px;
  line-height: 80px;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Heading3 = css`
  ${BaseFontFamily}
  font-weight: 400;

  font-size: 48px;
  line-height: 64px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const Heading4 = css`
  ${BaseFontFamily}

  font-size: 36px;
  line-height: 54px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Heading5 = css`
  ${BaseFontFamily}
  font-weight: 300;

  font-size: 28px;
  line-height: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Heading6 = css`
  ${BaseFontFamily}
  font-weight: 400;

  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Subtitle1 = css`
  ${BaseFontFamily}
  font-weight: 400;

  font-size: 18px;
  line-height: 32px;
`;

export const Subtitle2 = css`
  ${BaseFontFamily}
  font-weight: 300;

  font-size: 16px;
  line-height: 28px;
`;

export const Body1 = css`
  ${BaseFontFamily}
  font-weight: 400;

  font-size: 16px;
  line-height: 28px;
`;

export const Body2 = css`
  ${BaseFontFamily}
  font-weight: 300;

  font-size: 14px;
  line-height: 24px;
`;

export const Caption = css`
  ${BaseFontFamily}
  font-weight: 400;

  font-size: 20px;
  line-height: 20px;
`;
