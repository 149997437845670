import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../images/logo.png";
import loginBg from "../../images/_LoginBG_Image.png";
import "./ActivateUser.css";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertModal from "./Modals/AlertModal";
import { toast } from "react-toastify";

const ActivateUser = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [userData, setUserData] = useState<any>({});
  let { code } = useParams();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleAlertModalClose = () => setShowAlertModal(false);

  const showAlert = (message: any) => {
    setAlertMessage(message);
    setShowAlertModal(true);
  };

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { REACT_APP_USER_MANAGEMENT_API_BASE_URL, REACT_APP_API_BASE_URL } =
    process.env;

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_BASE_URL}/api/ConfirmUser`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
        toast.success("Successfully verified!");
        setIsLoading(false);
        navigate("/login");
        return res;
      })
      .catch((error) => {
        setIsLoading(false);
        const err = error?.message;
        if (!!err && typeof err === "string") {
          setErrMsg(err);
        } else if (!err) {
          setErrMsg("No Server Response");
        } else {
          setErrMsg("Failed!");
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      axiosPrivate.get(`GetUnconfirmedUserByCode?code=${code}`).then(
        (response) => {
          setUserData({ ...response?.data });
          setValue("email", response?.data?.cognitoUser?.email);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const resendConfirmationCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!userData?.cognitoUser?.email) {
      return;
    }
    setIsLoading(true);
    fetch(
      `${REACT_APP_USER_MANAGEMENT_API_BASE_URL}/api/ResendConfirmationCode`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: userData?.cognitoUser?.email }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
        setErrMsg("");
        showAlert(
          "Please check your email and enter the verification code you have received."
        );
        setIsLoading(false);
        return res;
      })
      .catch((error) => {
        setIsLoading(false);
        const err = error?.message;
        if (!!err && typeof err === "string") {
          setErrMsg(err);
        } else if (!err) {
          setErrMsg("No Server Response");
        } else {
          setErrMsg("Failed!");
        }
      });
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="bg-pic">
          <img className="lazy" src={loginBg} alt="" />
        </div>

        <div className="login-container">
          <div className="login-policy-wrapper">
            <div className="login-area">
              <img
                className="login-form-image"
                width={32}
                height={32}
                src={logo}
                alt=""
              />
              <div className="login-wrapper">
                {errMsg ? (
                  <p className="text-danger" aria-live="assertive">
                    {errMsg}
                  </p>
                ) : (
                  <p className="confirmation-message">
                    Please check your email and enter the verification code you
                    have received.
                  </p>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="username login-input">
                    <input
                      readOnly
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                      className={errors.email ? "invalid-field" : ""}
                    />
                    <span>EMAIL</span>
                  </div>
                  <div className="password login-input">
                    <input
                      type="password"
                      {...register("newPassword", {
                        required: true,
                      })}
                      aria-invalid={errors.password ? "true" : "false"}
                      className={errors.password ? "invalid-field" : ""}
                    />
                    <span>SET PASSWORD</span>
                  </div>
                  <div className="confirm-password login-input">
                    <input
                      type="password"
                      {...register("confirmPassword", {
                        required: true,
                        validate: (val: string) => {
                          if (watch("newPassword") !== val) {
                            return "Your passwords do no match";
                          }
                        },
                      })}
                      aria-invalid={errors.confirmPassword ? "true" : "false"}
                      className={errors.confirmPassword ? "invalid-field" : ""}
                    />
                    <span>CONFIRM PASSWORD</span>
                  </div>
                  <div className="password login-input">
                    <input
                      type="text"
                      {...register("code", {
                        required: true,
                      })}
                      aria-invalid={errors.code ? "true" : "false"}
                      className={errors.code ? "invalid-field" : ""}
                    />
                    <span>VERIFICATION CODE</span>
                  </div>
                  <div className="resend-confirmation-code-link-wrapper">
                    <a
                      className="resend-confirmation-code-link"
                      href="/"
                      onClick={resendConfirmationCode}
                    >
                      Resend Verification Code
                    </a>
                  </div>
                  <div className="login-form-button">
                    <button className="tbtc-btn" disabled={isLoading}>
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="policy-area">
              <p>
                We wanted to create a place where a community could come
                together through golf. The blockchain and NFTs in particular
                allow us to create an exclusive space where we can share a
                number of benefits with our digital community
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        message={alertMessage}
        isOpen={showAlertModal}
        handleClose={handleAlertModalClose}
      />
    </>
  );
};

export default ActivateUser;
