import React from "react";
import uuid from "react-uuid";
import userImage from "../../images/user.png";

const ImageModalDetails = ({ goatProfile, ownerWallet }: any) => {
  let footerImageFailed = true;
  let backgroundImageFailed = true;
  return (
    <div className="goatDetailsModal">
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <i
              className="fa-solid fa-xmark close-button"
              data-bs-dismiss="modal"
            ></i>
            <div className="row">
              <div className="col-md-7">
                <div className="thumbnail-wrapper">
                  <div
                    className="image-wrapper-main"
                    // style={{
                    //   backgroundImage: `url(${selectedNftImage?.image})`,
                    // }}
                  >
                    <img
                      src={`${goatProfile?.nftImage}?${Date.now()}`}
                      alt=""
                      onError={({ currentTarget }) => {
                        if (backgroundImageFailed) {
                          backgroundImageFailed = false;
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `${goatProfile?.fallbackImage}`;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-12 d-md-none d-lg-none d-xl-none">
                <div className="modal-item-footer-mobile">
                  <div className="left-info-modal-mobile">
                    {goatProfile?.cognitoUser?.picture ? (
                      <img
                        className="modal-profile-picture"
                        src={`${
                          goatProfile?.cognitoUser?.picture
                        }?${Date.now()}`}
                        alt=""
                      ></img>
                    ) : (
                      <img
                        className="modal-profile-picture"
                        src={userImage}
                        alt=""
                      ></img>
                    )}
                    <div className="left-info-modal-leftarea">
                      <h5 className="modal-full-name">
                        {goatProfile?.cognitoUser?.name}{" "}
                        {goatProfile?.cognitoUser?.familyName}
                      </h5>
                      <h4 className="whs-index">{goatProfile?.whsIndex}</h4>
                    </div>
                  </div>
                  <div className="modal-item-address-mobile">
                    {!!ownerWallet && (
                      <>
                        <h5>Wallet Address</h5>
                        <h4>{ownerWallet}</h4>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="modal-img-info">
                  <h3>
                    {!!goatProfile?.lockerNumber
                      ? `LOCKER #${goatProfile?.lockerNumber}`
                      : ""}
                  </h3>
                  <h4>Bio</h4>
                  <p>{goatProfile?.bio}</p>
                  <h6>Golf Courses</h6>
                  <div className="golf-courses-items">
                    {(goatProfile?.irlMembershipCourses || "")
                      .split(",")
                      .map((course: any) => (
                        <span key={uuid()}>{course}</span>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 ">
                <div className="modal-item-footer">
                  <div className="left-info-modal">
                    {goatProfile?.cognitoUser?.picture ? (
                      <img
                        src={`${
                          goatProfile?.cognitoUser?.picture
                        }?${Date.now()}`}
                        alt=""
                      ></img>
                    ) : (
                      <img src={userImage} alt=""></img>
                    )}
                    <div className="left-info-modal-leftarea">
                      <h5>
                        {goatProfile?.cognitoUser?.name}{" "}
                        {goatProfile?.cognitoUser?.familyName}
                      </h5>
                      <h4>{goatProfile?.whsIndex}</h4>
                    </div>
                  </div>
                  <div className="modal-item-adress">
                    {!!ownerWallet && (
                      <>
                        <h5>Wallet Address</h5>
                        <h4>{ownerWallet}</h4>
                      </>
                    )}
                  </div>
                  <div className="modal-footer-images">
                    <img
                      src={`${goatProfile?.nftThumbnail}?${Date.now()}`}
                      alt=""
                      onError={({ currentTarget }) => {
                        if (footerImageFailed) {
                          footerImageFailed = false;
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `${goatProfile?.fallbackImageThumbnail}`;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModalDetails;
