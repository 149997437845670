import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import loginBg from "../../images/_LoginBG_Image.png";
import "./Login.css";
import { Navigate } from "react-router-dom";
import jwt from "jwt-decode";
import userStorage from "../../common/userStorage";
import goatAxios from "../../api/goatAxios";
// import { TBTCUserAuthenticator } from "./TBTCUserAuthenticator";
import { TBTCUserAuthenticator } from "tbtc-sdk-react";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onSigninSuccess = async (data: any) => {
    setErrMsg("");
    const idToken: any = data?.idToken;
    var tokenObj: any = jwt(idToken);
    const groups = tokenObj["cognito:groups"];
    if (!(groups ?? []).includes("GOAT")) {
      // setErrMsg("User don't have permission to access the system!");
      toast.error("User don't have permission to access the system!");
      return;
    }
    const user =
      tokenObj["custom:web3"] === "1"
        ? tokenObj.preferred_username
        : tokenObj.email;
    const goatUserInfo = {
      ...data,
      user: user,
      web3: tokenObj["custom:web3"] === "1",
    };

    try {
      setIsLoading(true);
      const userResponse = await goatAxios.get("GetUserProfile", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${data?.accessToken}`,
        },
      });
      userResponse?.data && userStorage.saveUserInfo(userResponse?.data);
      userStorage.saveAuthInfo(goatUserInfo, userResponse?.data?.roles);
      setSuccess(true);
      setIsLoading(false);
      navigate(from === "/" ? `/Update/${userResponse?.data?.id}` : from, {
        replace: true,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };
  const {
    REACT_APP_USER_MANAGEMENT_API_BASE_URL,
    REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
  } = process.env;
  const styles: any = { tbtcSdkWrapper: { backgroundColor: "#202225" } };

  return (
    <>
      {success ? (
        <Navigate to="/" />
      ) : (
        <div className="login-wrapper">
          <div className="bg-pic">
            <img className="lazy" src={loginBg} alt="" />
          </div>

          <div className="login-container">
            <div className="login-policy-wrapper">
              <div className="login-area">
                {/* <img
                  className="login-form-image"
                  width={32}
                  height={32}
                  src={logo}
                  alt=""
                /> */}
                <div className="pointer" onClick={() => navigate("/")}>
                  <img
                    className="login-form-image"
                    width={32}
                    height={32}
                    src={logo}
                    alt=""
                  />
                </div>
                {errMsg && (
                  <p className="text-danger" aria-live="assertive">
                    {errMsg}
                  </p>
                )}
                <TBTCUserAuthenticator
                  groups="GOAT"
                  onSigninSuccess={onSigninSuccess}
                  signup={false}
                  userManagementApiBase={
                    REACT_APP_USER_MANAGEMENT_API_BASE_URL || ""
                    // REACT_APP_API_BASE_URL || ""
                  }
                  styles={styles}
                  googleRecaptchaSiteKey={REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                />
              </div>
              <div className="policy-area">
                <p>
                  We wanted to create a place where a community could come
                  together through golf. The blockchain and NFTs in particular
                  allow us to create an exclusive space where we can share a
                  number of benefits with our digital community
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
