import goatAxios from "../api/goatAxios";
import jwt from "jwt-decode";
import userStorage from "../common/userStorage";

const useRefreshToken = () => {
  const refresh = async () => {
    const userInfo: any = userStorage.getAuthInfo();
    const response = await goatAxios.post("RefreshAuthToken", {
      refreshToken: userInfo.refreshToken,
    });
    const idToken: any = response?.data?.idToken;
    var tokenObj: any = jwt(idToken);
    const roles: any = tokenObj["cognito:groups"];
    const goatUserInfo = {
      ...response?.data,
      user: tokenObj.email,
      roles,
    };
    userStorage.updateAuthToken(goatUserInfo);
    return response.data;
  };
  return refresh;
};

export default useRefreshToken;
