import axios from "axios";
import _ from "lodash";
declare let window: any;
const {
  REACT_APP_ETHERSCAN_API_KEY,
  REACT_APP_ETHERSCAN_API_BASE_URL,
  REACT_APP_ETHEREUM_NODE_URL,
} = process.env;

const Web3 = require("web3");
const web3 = new Web3(
  new Web3.providers.HttpProvider(REACT_APP_ETHEREUM_NODE_URL)
  // window.ethereum
);
const web3Write = new Web3(
  // new Web3.providers.HttpProvider(REACT_APP_ETHEREUM_NODE_URL)
  window.ethereum
);
const { REACT_APP_API_BASE_URL } = process.env;
const BASE_URL = `${REACT_APP_API_BASE_URL}/api`;

let contracts: any = [{ address: "", abi: "" }];
let defaultContractADDR: any;
const web3Service = {
  clearCache() {
    defaultContractADDR = null;
    contracts = [{ address: "", abi: "" }];
  },
  async getDefaultContractAddress() {
    if (!!defaultContractADDR) {
      return defaultContractADDR;
    }
    const response = await axios.get(`${BASE_URL}/GetSetting`);
    defaultContractADDR = response?.data?.contractAddress;
    return defaultContractADDR;
  },
  async getContract(contractAddress: string | null = null) {
    if (!contractAddress) {
      contractAddress = await this.getDefaultContractAddress();
    }
    const contract = _.find(contracts, { address: contractAddress });
    if (!!contract) {
      return new web3.eth.Contract(contract.abi, contract.address);
    }
    const response = await axios.get(
      `${REACT_APP_ETHERSCAN_API_BASE_URL}?module=contract&action=getabi&address=${contractAddress}&apikey=${REACT_APP_ETHERSCAN_API_KEY}`
    );
    if (response?.data?.status === "0") {
      throw new Error(response?.data?.result);
    }
    const abi = JSON.parse(response?.data?.result || "");
    contracts.push({ address: contractAddress, abi: abi });
    return new web3.eth.Contract(abi, contractAddress);
  },
  async getContractForWrite(contractAddress: string | null = null) {
    if (!contractAddress) {
      contractAddress = await this.getDefaultContractAddress();
    }
    const contract = _.find(contracts, { address: contractAddress });
    if (!!contract) {
      return new web3Write.eth.Contract(contract.abi, contract.address);
    }
    const response = await axios.get(
      `${REACT_APP_ETHERSCAN_API_BASE_URL}?module=contract&action=getabi&address=${contractAddress}&apikey=${REACT_APP_ETHERSCAN_API_KEY}`
    );
    if (response?.data?.status === "0") {
      throw new Error(response?.data?.result);
    }
    const abi = JSON.parse(response?.data?.result || "");
    contracts.push({ address: contractAddress, abi: abi });
    return new web3Write.eth.Contract(abi, contractAddress);
  },
  async owner(contractAddress: string | null = null) {
    try {
      const contract = await this.getContract(contractAddress);
      const owner = await contract.methods.owner().call();
      return owner;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async baseTokenURI(contractAddress: string | null = null) {
    try {
      const contract = await this.getContract(contractAddress);
      const tokenUri = await contract.methods.baseTokenURI().call();
      return tokenUri;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async tokenUri(tokenId: any) {
    try {
      const contract = await this.getContract();
      const address = await contract.methods.tokenURI(tokenId).call();
      return address;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async ownerOf(tokenId: any) {
    try {
      const contract = await this.getContract();
      const address = await contract.methods.ownerOf(tokenId).call();
      return address;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async getApproved(tokenId: any) {
    try {
      const contract = await this.getContract();
      const address = await contract.methods.getApproved(tokenId).call();
      return address;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async approve(from: any, to: any, tokenId: any) {
    const contract = await this.getContractForWrite();
    const result = await contract.methods
      .approve(to, tokenId)
      .send({ from: from });
    return result;
  },
  async sendToken(tokenId: any, receiver: any, signature: any) {
    const contract = await this.getContractForWrite();
    const result = await contract.methods
      .sendToken(tokenId, receiver, 0, signature)
      .send({ from: receiver });
    return result;
  },
};

export default web3Service;
