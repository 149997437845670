import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_WHITE } from "../colors";
import { Body1 } from "../Typography";

const ControlButton = styled.button`
  color: #000000 !important;
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  border: none;
  padding: 7px 18px;
  text-align: center;
  border-radius: 3px;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: none;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  &:hover {
    color: #ffffff !important;
    -ms-opacity: 0.95 !important;
    opacity: 0.95 !important;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

const Content = styled.div`
  ${Body1}
  text-align: left;
  display: flex;
  margin-bottom: 20px;
`;

const ModalContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${COLOR_WHITE};
  padding: 20px;
  border-radius: 10px;
`;

// const ExitButton = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   cursor: pointer;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${COLOR_GRAY};

//   &:after {
//     content: "✕";
//     font-size: x-large;
//     font-weight: bolder;
//   }

//   &:hover {
//     color: ${COLOR_BLACK};
//   }
// `;

type ConfirmationAlertModalProps = {
  message: string;
  handleCancel: () => void;
  handleOk: () => void;
};

export default function ConfirmationAlertModal({
  message,
  handleCancel,
  handleOk,
}: ConfirmationAlertModalProps) {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => setIsOpen(false);
  return (
    <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Body>
        <ModalContainer>
          <Content>{message}</Content>
          <ButtonContainer>
            <ControlButton
              onClick={() => {
                handleCancel();
                handleClose();
              }}
            >
              Cancel
            </ControlButton>{" "}
            <ControlButton
              onClick={() => {
                handleOk();
                handleClose();
              }}
            >
              Ok
            </ControlButton>
          </ButtonContainer>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}
