import { useEffect, useState } from "react";
// import { ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import uuid from "react-uuid";
import defaultProfileImage from "../../images/default-profile.png";
// import Dropzone from "../dropzone/Dropzone";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Register.css";
import web3Service from "../../common/web3Service";

const Register = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(defaultProfileImage);
  // const [nftImages, setNftImages] = useState<any[]>([]);
  const [profileImage, setProfileImage] = useState<any>(null);
  //For Dynamic Wallet Address Field
  // const [walletAddressFieldCount, setWalletAddressFieldCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // const [invalidImages, setInvalidImages] = useState<any>({});
  const axiosPrivate = useAxiosPrivate();
  const [goatSetting, setGoatSetting] = useState<any>({});

  const [phoneNumber, setPhoneNumber] = useState<any>();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => createGoatProfile(data);

  useEffect(() => {
    try {
      setIsLoading(true);
      axiosPrivate.get("GetSetting").then((response) => {
        setGoatSetting(response?.data || {});
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, []);

  const createGoatProfile = async (profile: any) => {
    try {
      if (!!profile.tokenId) {
        setIsLoading(true);
        var res = await axiosPrivate.post("IsDuplicateTokenId", {
          tokenId: profile.tokenId,
        });
        if (!!res.data) {
          setIsLoading(false);
          setError("tokenId", { type: "focus" }, { shouldFocus: true });
          toast.error("Duplicate Token Id");
          return;
        }
      }

      if (!!profile.lockerNumber) {
        setIsLoading(true);
        const duplicateLockerNumberResult = await axiosPrivate.post(
          "IsDuplicateLockerNumber",
          {
            lockerNumber: profile.lockerNumber,
            userId: profile.id,
          }
        );
        if (!!duplicateLockerNumberResult.data) {
          setIsLoading(false);
          setError("tokenId", { type: "focus" }, { shouldFocus: true });
          toast.error("Duplicate LOCKER#");
          return;
        }
      }

      const formDataRequestHeader = {
        headers: {
          "Content-type": "multipart/form-data",
        },
      };

      profile.tokenId = profile.tokenId || null;
      //profile.walletAddresses = profile.walletAddresses.filter((a: any) => !!a);
      const tokenUri = await web3Service.baseTokenURI();
      const response = await axiosPrivate.post("SignUpWeb2User", {
        ...profile,
        phoneNumber: phoneNumber,
        // tokenUri: tokenUri,
      });

      if (!!profileImage) {
        const formData = new FormData();
        formData.append("image", profileImage);
        formData.append("userId", response.data.id);
        setIsLoading(true);
        await axiosPrivate.post(
          "UploadProfilePicture",
          formData,
          formDataRequestHeader
        );
        setIsLoading(false);
      }
      if (profile.sendWelcomeEmail) {
        setIsLoading(true);
        await axiosPrivate.post(`SendWelcomeMail/${response.data.id}`);
        setIsLoading(false);
      }
      setIsLoading(false);
      toast.success("Profile added!");
      navigate("/");
    } catch (err: any) {
      // console.log(err);
      setIsLoading(false);
      toast.error(err?.response?.data || "Failed!");
    }
  };

  const handleProfilePictureUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!!selectedFile) {
      setProfilePicture(URL.createObjectURL(selectedFile));
      setProfileImage(selectedFile);
    }
  };
  //Progressbar Value
  // const now = 100;

  return (
    <>
      <div className={isLoading ? "disabled register-main" : "register-main"}>
        <Header />
        <div className="register-form">
          <form
            className="tbtc-form-wrapper tbtc-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3>Add New Profile</h3>

            <div className="row">
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    aria-invalid={errors.email ? "true" : "false"}
                    className={errors.email ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>EMAIL</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("name", {
                      required: true,
                    })}
                    aria-invalid={errors.name ? "true" : "false"}
                    className={errors.name ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>NAME</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("familyName", {
                      required: true,
                    })}
                    aria-invalid={errors.familyName ? "true" : "false"}
                    className={errors.familyName ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>SURNAME</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("lockerNumber", {
                      minLength: 4,
                      validate: {
                        lockerNumberValidation: (val: string) => {
                          return !val || /^\d+$/.test(val);
                        },
                      },
                    })}
                    aria-invalid={errors.lockerNumber ? "true" : "false"}
                    className={errors.lockerNumber ? "invalid-field" : ""}
                  />
                  <label>LOCKER #</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="number"
                    {...register("tokenId", {})}
                    aria-invalid={errors.tokenId ? "true" : "false"}
                    className={errors.tokenId ? "invalid-field" : ""}
                  />
                  <label>Token Id</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="text"
                    value={goatSetting?.contractAddress || ""}
                    name="contractAddress"
                    disabled
                  />
                  <label>Contract Address</label>
                  <span
                    className="copy-button"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(
                        goatSetting?.contractAddress
                      );
                    }}
                  >
                    Copy
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="text"
                    value={goatSetting?.tokenUri || ""}
                    name="tokenUri"
                    disabled
                  />
                  <label>Token URI</label>
                  <span
                    className="copy-button"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(goatSetting?.tokenUri);
                    }}
                  >
                    Copy
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="phone-number goat-phone-number">
                  <PhoneInput
                    country="gb"
                    placeholder=""
                    value={phoneNumber}
                    enableSearch={true}
                    onChange={(num: any) => {
                      setPhoneNumber(num && `+${num}`);
                    }}
                  />
                  <label className="phone-number-label">PHONE NUMBER</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("whsIndex")}
                    aria-invalid={errors.whsIndex ? "true" : "false"}
                    className={errors.whsIndex ? "invalid-field" : ""}
                  />
                  <label>WHS INDEX</label>
                </div>
              </div>
            </div>
            {/* <div className="email-name-surname-wrapper">
              <div className="email">
                <input
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                  className={errors.email ? "invalid-field" : ""}
                />
                <div className="required-star">*</div>
                <span>EMAIL</span>
              </div>
              <div className="first-name">
                <input
                  {...register("name", {
                    required: true,
                  })}
                  aria-invalid={errors.name ? "true" : "false"}
                  className={errors.name ? "invalid-field" : ""}
                />
                <div className="required-star">*</div>
                <span>NAME</span>
              </div>
              <div className="surname">
                <input
                  {...register("familyName", {
                    required: true,
                  })}
                  aria-invalid={errors.familyName ? "true" : "false"}
                  className={errors.familyName ? "invalid-field" : ""}
                />
                <div className="required-star">*</div>
                <span>SURNAME</span>
              </div>
            </div> */}

            {/* <div className="email-name-surname-wrapper">
              <div className="phone-number goat-phone-number">
                <PhoneInput
                  country="gb"
                  placeholder=""
                  value={phoneNumber}
                  enableSearch={true}
                  onChange={(num: any) => {
                    setPhoneNumber(num && `+${num}`);
                  }}
                />
                <label className="phone-number-label">PHONE NUMBER</label>
              </div>
              <div className="whs-index">
                <input
                  {...register("whsIndex")}
                  aria-invalid={errors.whsIndex ? "true" : "false"}
                  className={errors.whsIndex ? "invalid-field" : ""}
                />
                <span>WHS INDEX</span>
              </div>
              <div className="img-picker">
                <div className="input--file">
                  <div>
                    <img
                      src={profilePicture}
                      alt=""
                      className="rounded-circle profile-picture"
                      width={40}
                      height={40}
                    />
                  </div>
                  <input
                    name="Select File"
                    type="file"
                    onChange={handleProfilePictureUpload}
                  />
                </div>
                <span className="all-img-heading">
                  Update your profile pic so others know who you are.
                </span>
              </div>
            </div> */}
            <div className="row m-t-10">
              <div className="col-md-3 d-flex align-items-center">
                <div className="tbtc-checkbox">
                  <input type="checkbox" {...register("isSuperAdmin")} />
                  <label>Super Admin</label>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <div className="tbtc-checkbox">
                  <input type="checkbox" {...register("isPublic")} />
                  <label>Is Public</label>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <div className="tbtc-checkbox">
                  <input type="checkbox" {...register("isGolfer")} />
                  <label>Is IRL Golfer</label>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <div className="img-picker">
                  <div className="input--file">
                    <div>
                      <img
                        src={profilePicture}
                        alt=""
                        className="rounded-circle profile-picture"
                        width={40}
                        height={40}
                      />
                    </div>
                    <input
                      name="Select File"
                      type="file"
                      onChange={handleProfilePictureUpload}
                    />
                  </div>
                  <span className="all-img-heading">
                    Update your profile pic so others know who you are.
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="mobile-index-public-admin-wrapper three-coloumn-wrapper">
              <div className="is-super-admin">
                <input type="checkbox" {...register("isSuperAdmin")} />
                <span>Super Admin</span>
              </div>
              <div className="is-public">
                <input type="checkbox" {...register("isPublic")} />
                <span>Is Public</span>
              </div>
              <div className="is-golfer">
                <input type="checkbox" {...register("isGolfer")} />
                <span>Is IRL Golfer</span>
              </div>
            </div> */}

            {/* <div className="wallet-adress-golfer-img-picker-wrapper">
              <div className="wallet-addresses">
                {Array(walletAddressFieldCount)
                  .fill(0)
                  .map((_: any, index: any) => (
                    <div key={index} className="wallet-address mb-1">
                      <input {...register("walletAddresses[" + index + "]")} />
                      {index === 0 && (
                        <i
                          onClick={addWalletField}
                          style={{ cursor: "pointer" }}
                          className="fa-solid fa-plus"
                        ></i>
                      )}
                      {index > 0 && (
                        <i
                          onClick={() => removeWalletField(index)}
                          className="fa-solid fa-minus"
                          style={{ color: "red", cursor: "pointer" }}
                        ></i>
                      )}
                      <span>WALLET ADDRESS</span>
                    </div>
                  ))}
              </div>
              <div className="is-golfer">
                <input type="checkbox" {...register("isGolfer")} />
                <span>Is IRL Golfer</span>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <textarea
                    {...register("bio")}
                    aria-invalid={errors.bio ? "true" : "false"}
                    className={errors.bio ? "invalid-field" : ""}
                  />
                  <label>BIO</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <textarea
                    {...register("IrlMembershipCourses")}
                    aria-invalid={
                      errors.IrlMembershipCourses ? "true" : "false"
                    }
                    className={
                      errors.IrlMembershipCourses ? "invalid-field" : ""
                    }
                  />
                  <label>IRL MEMBERSHIP COURSES</label>
                </div>
              </div>
            </div>
            {/* <div className="create-confirm-password-wrapper">
              <div className="create-password">
                <input
                  type="password"
                  {...register("password", {
                    required: true,
                  })}
                  aria-invalid={errors.password ? "true" : "false"}
                  className={errors.password ? "invalid-field" : ""}
                />
                <span>CREATE PASSWORD</span>
                <div className="required-star">*</div>
              </div>
              <div className="confirm-password">
                <input
                  type="password"
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val: string) => {
                      if (watch("password") !== val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  aria-invalid={errors.confirmPassword ? "true" : "false"}
                  className={errors.confirmPassword ? "invalid-field" : ""}
                />
                <span>CONFIRM PASSWORD</span>
                <div className="required-star">*</div>
              </div>
            </div> */}
            {/* <div className="dropzone-wrapper">
              <Dropzone handleNftImageUpload={handleNftImageUpload} />
            </div>
            {nftImages.map((nft: any) => (
              <div className="locker-progress" key={nft.id}>
                <div className="image-item">
                  <img
                    src={URL.createObjectURL(nft.image)}
                    alt=""
                    height={50}
                    width={50}
                  />
                </div>
                <div className="locker-progress-item">
                  <div className="progress-item">
                    <div className="progress">
                      <ProgressBar
                        className="progress-bar-item"
                        now={now}
                        label={`${now}%`}
                      />
                    </div>
                  </div>
                  <div className="checkbox-delete-area">
                    <div className="remove-btn">
                      <i
                        onClick={() => {
                          removeNftImage(nft);
                        }}
                        className="fa-solid fa-trash-can"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}

            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <div className="d-flex m-r-10 align-items-center">
                  <div className="tbtc-checkbox">
                    <input
                      defaultChecked={true}
                      type="checkbox"
                      {...register("sendWelcomeEmail")}
                    />
                    <label>Send Welcome Email</label>
                  </div>
                </div>
                <div className="d-flex">
                  <button className="tbtc-btn" type="submit">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
