// import { Modal } from "@mui/material";
import styled from "styled-components";
import { COLOR_BLACK, COLOR_GRAY, COLOR_WHITE } from "../colors";
import { Heading4 } from ".././Typography";
import { Modal } from "react-bootstrap";

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${COLOR_WHITE};
  padding: 50px;
  border-radius: 10px;
`;

const ExitButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_GRAY};

  &:after {
    content: "✕";
    font-size: x-large;
    font-weight: bolder;
  }

  &:hover {
    color: ${COLOR_BLACK};
  }
`;

const Title = styled.h1`
  ${Heading4}
  color: ${COLOR_GRAY};
  text-align: center;
`;

type Props = {
  isOpen: boolean;
  handleClose?: () => void;
  children: React.ReactNode;
  title?: string;
};

export default function WhiteModal({
  isOpen: open,
  handleClose,
  title,
  children,
}: Props) {
  return (
    <>
      {/* <Modal open={open} onClose={handleClose} disableScrollLock>
      <ModalBox>
        {title && <Title>{title}</Title>}
        <ModalContent>
          <ExitButton onClick={handleClose} />
          {children}
        </ModalContent>
      </ModalBox>
    </Modal> */}

      <Modal show={open} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
          <Modal.Title> {title && <Title>{title}</Title>}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <ModalContent>
            <ExitButton onClick={handleClose} />
            {/* {title && <Title>{title}</Title>} */}
            {children}
          </ModalContent>
        </Modal.Body>
      </Modal>
    </>
  );
}
