import { Route, Routes } from "react-router-dom";
import "./App.css";
import RequireAuth from "./auths/RequireAuth";
import Unauthorized from "./auths/Unauthorized";
import Layout from "./common/Layout";
import Home from "./features/home/Home";
import Login from "./features/login/Login";
import Register from "./features/register/Register";
import Updater from "./features/updater/Updater";
import { ToastContainer } from "react-toastify";
import GoatSetting from "./features/goatSetting/GoatSetting";
import ActivateUser from "./features/login/ActivateUser";
import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/confirm/:code" element={<ActivateUser />}></Route>
          {/* <Route path="/activate-user/:code" element={<ActivateUser />}></Route> */}
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route element={<RequireAuth />}>
            <Route
              path="/update/:id"
              element={
                <Web3ReactProvider getLibrary={getLibrary}>
                  <Updater />{" "}
                </Web3ReactProvider>
              }
            ></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
            <Route path="/register" element={<Register />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["SuperAdmin"]} />}>
            <Route path="/setting" element={<GoatSetting />}></Route>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default App;
