import React from "react";
import bgImg from "../../images/TBTCGOATGalleryBanner_WB.png";
import gifImg from "../../images/TBTCGold3DSpining.gif";
import "./Banner.css";

export const Banner = () => {
  return (
    <header>
      <img className="banner-img" width="100%" src={bgImg} alt="" />
      <div className="gif-img">
        <img src={gifImg} alt="" />
      </div>
    </header>
  );
};
