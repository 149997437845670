import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import web3Service from "../../common/web3Service";
import Web3ConnectModal from "../login/Web3ConnectModal";
import { isMobile } from "react-device-detect";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
declare let window: any;

const OwnershipChecker = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentWallet, setCurrentWallet] = useState("");
  const [delegatedWallet, setDelegatedWallet] = useState("");
  const [goatProfile, setGoatProfile] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  const WalletConnect = new WalletConnectConnector({
    // @ts-ignore
    rpcUrl: "https://rinkeby.infura.io/v3/9d097d80ee584f869bf6588285180249",
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
  });

  const [showWalletConnectionModal, setShowWalletConnectionModal] =
    useState(false);

  const handleWalletConnectionModalClose = () =>
    setShowWalletConnectionModal(false);
  const handleWalletConnectionModalShow = (event: any) => {
    event.preventDefault();
    setShowWalletConnectionModal(true);
  };

  const { account, activate, library } = useWeb3React();

  const addWalletConnectWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    // disconnect();
    // setIsLoading(true);
    await activate(WalletConnect);
  };
  useEffect(() => {
    if (!!account) {
      approve(account);
    }
  }, [account]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const axiosPrivate = useAxiosPrivate();
  const handleClose = () => setShow(false);

  useImperativeHandle(ref, () => ({
    async showOwnershipCheckModal(user: any) {
      setGoatProfile(user);
      reset();
      setShow(true);
      setIsLoading(false);
      const currentWalletAddress = await web3Service.ownerOf(user?.tokenId);
      setCurrentWallet(currentWalletAddress);
      const delegatedWalletAddress = await web3Service.getApproved(
        user?.tokenId
      );
      setDelegatedWallet(delegatedWalletAddress);
    },
  }));
  const showSuccessMessageAndCloseModal = () => {
    toast.success("Completed!");
    handleClose();
  };

  const addMetamaskWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    if (window.ethereum && window.ethereum.isMetaMask) {
      let accounts = [];
      if (isMobile) {
        accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } else {
        accounts = await window.ethereum
          .request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(() =>
            window.ethereum.request({
              method: "eth_requestAccounts",
            })
          );
      }
      if (!!accounts) {
        accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const address = accounts[0];
        await approve(address);
      }
    }
  };

  const approve = async (address: any) => {
    try {
      let toAddress = "";
      if (formData.walletSelector === "new-wallet") {
        if (!formData.walletAddress) {
          toast.error("Wallet address is required!");
          return;
        }
        toAddress = formData.walletAddress;
      } else {
        toAddress = await web3Service.owner();
      }
      var res = await web3Service.approve(
        address,
        toAddress,
        goatProfile?.tokenId
      );
      const selectedOption =
        formData.walletSelector === "new-wallet"
          ? 'Enter a new wallet address that you wish to have delegated "APPROVAL" access'
          : "Transfer delegated ownership to TBTC admin";
      const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
        subject: "Ownership Check",
        body: `Selected Option: ${selectedOption}\nTo Wallet Address: ${toAddress}\nConnected Wallet Address: ${address}\nToken ID: ${goatProfile?.tokenId}`,
      });
      toast.success("Successfull!");
    } catch (error) {
      toast.error("Failed!");
    }
  };
  const onSubmit = async (data: any) => {
    try {
      setFormData(data);
      handleClose();
      setShowWalletConnectionModal(true);
      // showSuccessMessageAndCloseModal();
    } catch (error) {
      toast.error("Failed!");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={isLoading ? "disabled tbtc-modal" : "tbtc-modal"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            This is current status of your TBTC GOAT NFT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} className="tbtc-form">
            <div className="row m-t-5">
              <div className="col-md-12">
                <div className="tbtc-form-group">
                  <input disabled type="text" value={currentWallet || ""} />
                  <label>Current Owner</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="tbtc-form-group">
                  <input disabled type="text" value={delegatedWallet || ""} />
                  <label>Delegated Owner</label>
                </div>
              </div>
            </div>
            <div className="row m-t-10">
              <div className="col-md-12">
                <div className="form-check tbtc-radio">
                  <div>
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("walletSelector")}
                      value="delegated-wallet"
                    />
                  </div>
                  <label>Transfer delegated ownership to TBTC admin</label>
                </div>
              </div>
            </div>
            <div className="row m-t-10">
              <div className="col-md-12">
                <div className="form-check tbtc-radio">
                  <div>
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("walletSelector")}
                      value="new-wallet"
                    />
                  </div>
                  <label>
                    Enter a new wallet address that you wish to have delegated
                    "APPROVAL" access
                  </label>
                </div>
              </div>
            </div>
            {watch("walletSelector") === "new-wallet" && (
              <div className="row m-t-10">
                <div className="col-md-12">
                  <input
                    type="text"
                    {...register("walletAddress", {
                      validate: {
                        walletAddressValidation: (val: string) => {
                          return (
                            watch("walletSelector") === "new-wallet" && !!val
                          );
                        },
                      },
                    })}
                    aria-invalid={errors.walletAddress ? "true" : "false"}
                    className={
                      errors.walletAddress
                        ? "invalid-field form-control tbtc-input"
                        : "form-control tbtc-input"
                    }
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12 m-t-10">
                <p className="padding-0 margin-0 fs-15">
                  This will include gas fees
                </p>
              </div>
            </div>
            <div className="row m-t-10">
              <div className="col-m-12 d-flex justify-content-end padding-0">
                <button className="tbtc-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <Web3ConnectModal
        isOpen={showWalletConnectionModal}
        handleClose={() => handleWalletConnectionModalClose()}
        handleMetamask={addMetamaskWallet}
        handleWalletConnect={addWalletConnectWallet}
      />
    </>
  );
});

export default OwnershipChecker;
