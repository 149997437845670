import { useEffect } from "react";
import { goatAxiosPrivate } from "../api/goatAxios";
import userStorage from "../common/userStorage";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  useEffect(() => {
    const userInfo = userStorage.getAuthInfo();
    const requestIntercept = goatAxiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${userInfo?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = goatAxiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !prevRequest?.sent
        ) {
          //prevRequest.sent = true;
          const data = await refresh();
          //prevRequest.headers["Authorization"] = `Bearer ${data.idToken}`;
          return goatAxiosPrivate({
            ...prevRequest,
            headers: { Authorization: `Bearer ${data.accessToken}` },
            sent: true,
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      goatAxiosPrivate.interceptors.request.eject(requestIntercept);
      goatAxiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refresh]);

  return goatAxiosPrivate;
};

export default useAxiosPrivate;
