import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import web3Service from "../../common/web3Service";
import AlertModal from "../login/Modals/AlertModal";
import _ from "lodash";
import "./ManageGoat.css";
import Web3ConnectModal from "../login/Web3ConnectModal";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import axios from "axios";
declare let window: any;

const ManageGoat = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [goatProfile, setGoatProfile] = useState<any>({});
  const [ownerWallet, setOwnerWallet] = useState<any>({});
  const [selectedWallet, setSelectedWallet] = useState<any>({});
  const [step, setStep] = useState<any>(1);
  const [signatureData, setSignatureData] = useState<any>({});
  const [showRequestBtn, setShowRequestBtn] = useState<any>(false);
  const [showApprovalRequestBtn, setShowApprovalRequestBtn] =
    useState<boolean>(false);
  const [displayObj, setDisplayObj] = useState<any>({
    enabledTxt: "Enable",
    approvedTxt: "Approval",
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleAlertModalClose = () => setShowAlertModal(false);

  const showAlert = (message: any) => {
    setAlertMessage(message);
    setShowAlertModal(true);
  };

  const WalletConnect = new WalletConnectConnector({
    // @ts-ignore
    rpcUrl: "https://rinkeby.infura.io/v3/9d097d80ee584f869bf6588285180249",
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
  });
  const [showWalletConnectionModal, setShowWalletConnectionModal] =
    useState(false);

  const handleWalletConnectionModalClose = () =>
    setShowWalletConnectionModal(false);
  const handleWalletConnectionModalShow = (event: any) => {
    event.preventDefault();
    setShowWalletConnectionModal(true);
  };

  const { account, activate, library } = useWeb3React();

  const addWalletConnectWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    // disconnect();
    // setIsLoading(true);
    await activate(WalletConnect);
  };
  useEffect(() => {
    if (!!account) {
      approve(account);
    }
  }, [account]);

  const axiosPrivate = useAxiosPrivate();
  const handleClose = () => setShow(false);

  useImperativeHandle(ref, () => ({
    showManageGoatModal(user: any) {
      setStep(1);
      setGoatProfile(user);
      reset();
      setShow(true);
      setIsLoading(false);
    },
  }));
  const showSuccessMessageAndCloseModal = () => {
    toast.success("Email has been sent successfully!");
    handleClose();
  };
  const onSubmit = async (data: any) => {
    try {
      if (data.goatSelector === "invite-new-goat") {
        setIsLoading(true);
        await axiosPrivate.post("SendInvitationMail", {
          email: data.email,
        });
        setIsLoading(false);
        showSuccessMessageAndCloseModal();
      } else if (data.goatSelector === "find-me-buyer") {
        setIsLoading(true);
        await axiosPrivate.post("SendFindBuyerMail", {
          email: goatProfile.cognitoUser.email,
          name: goatProfile.cognitoUser.name,
          familyName: goatProfile.cognitoUser.familyName,
          tokenId: goatProfile.tokenId,
          lockerNumber: goatProfile.lockerNumber,
        });
        setIsLoading(false);
        showSuccessMessageAndCloseModal();
        setIsLoading(false);
      } else if (data.goatSelector === "start-transfer") {
        if (goatProfile?.tokenId == null) {
          toast.error("TokenId not found!");
          return;
        }
        if (!!data?.walletAddress) {
          setIsLoading(true);
          setSelectedWallet(data?.walletAddress);
          const ownerWallet = await web3Service.ownerOf(goatProfile?.tokenId);
          setOwnerWallet(ownerWallet);
          const sigData = await getSigData(data?.walletAddress);
          let sig = filterSigDataByWallet(
            sigData,
            data?.walletAddress,
            ownerWallet
          );

          //for local testing

          // sig = [
          //   {
          //     tokenId: 1,
          //     from: "0x2e09d8da2bfa81fc20d0698b6d966b29eb2304ab",
          //     to: "0xb042412310bbe90917e0bb0c75dcbe05f5c04e46",
          //     price: "0",
          //     signature:
          //       "0x1e9f817f057cf5884f846579991e51d74a052eeedf633636faf752aa6e7f18774b29f9a1992444cf27d85711ba4f2be1f20f7f6e9251c70f488b162d532a45721b",
          //   },
          // ];
          ////////////////

          setSignatureData(sig);

          if (sig) {
            setIsLoading(false);
            if (!(await completeApproval(data?.walletAddress))) {
              return;
            }
            if (!(await completeTransfer(data?.walletAddress))) {
              return;
            }
            setStep(4);
          } else {
            const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
              subject: "Sig App details",
              body: `Owner Wallet Address: ${ownerWallet}\nTo Wallet Address: ${data?.walletAddress}\nToken ID: ${goatProfile?.tokenId}`,
            });
            showAlert(
              "Your request has been sent to TBTC please check back shortly on your status."
            );
            handleClose();
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed!");
      console.error(error);
      handleClose();
    }
  };

  const completeApproval = async (walletAddress: any) => {
    setShowApprovalRequestBtn(false);
    setStep(2);
    setDisplayObj({
      enabledTxt: "Checking...",
      approvedTxt: "Approval",
    });
    const ownerWallet = await web3Service.ownerOf(goatProfile?.tokenId);
    const approvedWallet = await web3Service.getApproved(goatProfile?.tokenId);
    if (
      !approvedWallet ||
      approvedWallet === "0x0000000000000000000000000000000000000000"
    ) {
      setShowApprovalRequestBtn(true);

      //always send email even if the user don't click Request Update button

      const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
        subject: "Wallet Approval Delay User Waiting!!",
        body: `Owner Wallet Address: ${ownerWallet}\nTo Wallet Address: ${walletAddress}\nToken ID: ${goatProfile?.tokenId}`,
      });
      setDisplayObj({
        enabledTxt: "Failed",
        approvedTxt: "Approval",
      });
      return false;
    }

    setDisplayObj({
      enabledTxt: "Enabled",
      approvedTxt: "Checking...",
    });

    const userResponse = await axiosPrivate.get(
      `GetUserByWalletAddress/${walletAddress}`
    );
    if (!userResponse?.data?.enabled) {
      setDisplayObj({
        enabledTxt: "Enabled",
        approvedTxt: "Failed",
      });
      handleClose();
      showAlert(
        "Your selected wallet is disabled please renable so you can come back and continue."
      );

      return false;
    }

    const sigData = await getSigData(walletAddress);
    let sig = filterSigDataByWallet(sigData, walletAddress, ownerWallet);

    if (!sig) {
      setShowApprovalRequestBtn(true);
      const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
        subject: "Sig App Delay User Waiting!!",
        body: `Owner Wallet Address: ${ownerWallet}\nTo Wallet Address: ${walletAddress}\nToken ID: ${
          goatProfile?.tokenId
        }\nSigs: ${JSON.stringify(sigData)}`,
      });
      setDisplayObj({
        enabledTxt: "Enabled",
        approvedTxt: "Failed",
      });
      return false;
    }

    setDisplayObj({
      enabledTxt: "Enabled",
      approvedTxt: "Approved",
    });
    return true;
  };
  const completeTransfer = async (walletAddress: any) => {
    setShowRequestBtn(false);
    setStep(3);
    setDisplayObj({
      enabledTxt: "Enabled",
      approvedTxt: "Checking...",
    });

    const ownerWallet = await web3Service.ownerOf(goatProfile?.tokenId);

    const sigData = await getSigData(walletAddress);
    let sig = filterSigDataByWallet(sigData, walletAddress, ownerWallet);

    //for local testing

    // sig = [
    //   {
    //     tokenId: 1,
    //     from: "0x2e09d8da2bfa81fc20d0698b6d966b29eb2304ab",
    //     to: "0xb042412310bbe90917e0bb0c75dcbe05f5c04e46",
    //     price: "0",
    //     signature:
    //       "0x1e9f817f057cf5884f846579991e51d74a052eeedf633636faf752aa6e7f18774b29f9a1992444cf27d85711ba4f2be1f20f7f6e9251c70f488b162d532a45721b",
    //   },
    // ];
    ////////////////

    setSignatureData(sig);
    if (!sig) {
      setShowRequestBtn(true);

      //always send email even if the user don't click Request Update button

      const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
        subject: "Sig App Delay User Waiting!!",
        body: `Owner Wallet Address: ${ownerWallet}\nTo Wallet Address: ${walletAddress}\nToken ID: ${
          goatProfile?.tokenId
        }\nSigs: ${JSON.stringify(sigData)}`,
      });
      setDisplayObj({
        enabledTxt: "Enabled",
        approvedTxt: "Failed",
      });
      return false;
    } else {
      setDisplayObj({
        enabledTxt: "Enabled",
        approvedTxt: "Approved",
      });
    }
    return true;
  };

  const confirmTransfer = async () => {
    handleClose();
    setShowWalletConnectionModal(true);
  };

  const getSigData = async (walletAddress: any) => {
    try {
      const settingResponse = await axiosPrivate.get("GetSetting");
      const setting = settingResponse?.data;
      if (!!setting?.sigAppApi) {
        const sigResponse = await axios.get(
          `${setting?.sigAppApi?.replace(
            /\/$/,
            ""
          )}/api/goat-signature/buy/${walletAddress}`
        );
        return sigResponse?.data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const filterSigDataByWallet = (
    sigData: any,
    walletAddress: any,
    ownerWallet: any
  ) => {
    if (_.isEmpty(sigData)) {
      return null;
    }
    var sig = _.first(
      _.filter(sigData || [], function (sig) {
        return (
          sig?.tokenId === goatProfile?.tokenId &&
          (sig?.from || "").toLowerCase() ===
            (ownerWallet || "").toLowerCase() &&
          (sig?.to || "").toLowerCase() === (walletAddress || "").toLowerCase()
        );
      })
    );
    return sig;
  };

  const addMetamaskWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    if (window.ethereum && window.ethereum.isMetaMask) {
      let accounts = [];
      if (isMobile) {
        accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } else {
        accounts = await window.ethereum
          .request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(() =>
            window.ethereum.request({
              method: "eth_requestAccounts",
            })
          );
      }
      if (!!accounts) {
        accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const address = accounts[0];
        await approve(address);
      }
    }
  };

  const approve = async (address: any) => {
    try {
      var res = await web3Service.sendToken(
        goatProfile?.tokenId,
        address,
        signatureData?.signature
      );

      toast.success("Successfull!");
    } catch (error) {
      console.error(error);
      toast.error("Failed!");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={isLoading ? "disabled tbtc-modal" : "tbtc-modal"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>GOAT MANAGEMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 2 ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <ul className="m-t-10" id="progress">
                    <li className="active">Approval</li>
                    <li>Transfer</li>
                    <li>Complete</li>
                  </ul>
                </div>
              </div>
              <div className="row m-t-10">
                <div className="col-md-6 d-flex justify-content-center">
                  <div
                    className={
                      "goat-circle " +
                      (displayObj?.enabledTxt === "Enabled"
                        ? "success-goat-circle"
                        : displayObj?.enabledTxt === "Failed"
                        ? "failed-goat-circle"
                        : "")
                    }
                  >
                    {displayObj?.enabledTxt}
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  <div
                    className={
                      "goat-circle " +
                      (displayObj?.approvedTxt === "Approved"
                        ? "success-goat-circle"
                        : displayObj?.approvedTxt === "Failed"
                        ? "failed-goat-circle"
                        : "")
                    }
                  >
                    {displayObj?.approvedTxt}
                  </div>
                </div>
              </div>
              {showApprovalRequestBtn && (
                <div className="row m-t-10">
                  <div className="col-md-12 d-flex justify-content-end">
                    <button
                      className="tbtc-btn"
                      type="button"
                      onClick={(event) => {
                        event?.preventDefault();
                        handleClose();
                      }}
                    >
                      Request Update
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : step === 3 ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <ul className="m-t-10" id="progress">
                    <li className="completed">Approval</li>
                    <li className="active">Transfer</li>
                    <li>Complete</li>
                  </ul>
                </div>
              </div>
              <div className="row m-t-10">
                <div className="col-md-12 d-flex justify-content-center">
                  <div
                    className={
                      "goat-circle " +
                      (displayObj?.approvedTxt === "Approved"
                        ? "success-goat-circle"
                        : displayObj?.approvedTxt === "Failed"
                        ? "failed-goat-circle"
                        : "")
                    }
                  >
                    {displayObj?.approvedTxt}
                  </div>
                </div>
              </div>
              {showRequestBtn && (
                <div className="row m-t-10">
                  <div className="col-md-12 d-flex justify-content-end">
                    <button
                      className="tbtc-btn"
                      type="button"
                      onClick={(event) => {
                        event?.preventDefault();
                        handleClose();
                      }}
                    >
                      Request Update
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : step === 4 ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <ul className="m-t-10" id="progress">
                    <li className="completed">Approval</li>
                    <li className="completed">Transfer</li>
                    <li className="active">Complete</li>
                  </ul>
                </div>
              </div>
              <div className="row m-t-10">
                <div className="col-md-12">
                  <p className="padding-0 margin-0 fs-sm-13">
                    You are about to complete the following transaction - Send
                    token ID {goatProfile?.tokenId} from {ownerWallet} to{" "}
                    {selectedWallet} this will be processed by the blockchain
                    please make sure you are signing in with {selectedWallet} to
                    sign this transaction, this will incur gas fees, do you wish
                    to continue?
                  </p>
                </div>
              </div>
              <div className="row m-t-10">
                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    className="tbtc-btn"
                    type="button"
                    onClick={(event) => {
                      event?.preventDefault();
                      confirmTransfer();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="tbtc-form">
              <div className="row m-t-10">
                <div className="col-md-12">
                  <div className="form-check tbtc-radio">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        {...register("goatSelector")}
                        value="invite-new-goat"
                      />
                    </div>
                    <label className="form-check-label">
                      Invite a new GOAT
                    </label>
                  </div>
                </div>
              </div>
              {watch("goatSelector") === "invite-new-goat" && (
                <div className="row m-t-10">
                  <div className="col-md-12">
                    <input
                      type="email"
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                        validate: {
                          inviteNewGoat: (val: string) =>
                            watch("goatSelector") === "invite-new-goat" &&
                            !!val,
                        },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                      className={
                        errors.email
                          ? "invalid-field form-control tbtc-input"
                          : "form-control tbtc-input"
                      }
                    />
                  </div>
                </div>
              )}

              <div className="row m-t-10">
                <div className="col-md-12">
                  <div className="form-check tbtc-radio">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="find-me-buyer"
                        {...register("goatSelector")}
                        value="find-me-buyer"
                      />
                    </div>
                    <label className="form-check-label">
                      Please find me a buyer
                    </label>
                  </div>
                </div>
              </div>

              <div className="row m-t-10">
                <div className="col-md-12">
                  <div className="form-check tbtc-radio">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        {...register("goatSelector")}
                        value="start-transfer"
                      />
                    </div>
                    <label>
                      Start Transfer (we will not hold states on this it is a
                      complete or start again process){" "}
                    </label>
                  </div>
                </div>
              </div>

              {watch("goatSelector") === "start-transfer" && (
                <>
                  <div className="row m-t-5">
                    <div className="col-md-12">
                      <p className="padding-0 margin-0 fs-15">
                        You are about to starts the transfer process please
                        select the wallet you wish your NFT to be sent to
                      </p>
                    </div>
                  </div>
                  <div className="row m-t-5">
                    <div className="col-md-12">
                      <div className="tbtc-form-group">
                        <select
                          className={
                            errors.walletAddress
                              ? "invalid-field form-select"
                              : "form-select"
                          }
                          {...register("walletAddress", {
                            validate: {
                              inviteNewGoat: (val: string) =>
                                watch("goatSelector") === "start-transfer" &&
                                !!val,
                            },
                          })}
                        >
                          {goatProfile?.cognitoUser?.linkedUsers
                            ?.filter((g: any) => g.enabled)
                            ?.map((wallet: any) => (
                              <option key={wallet?.id}>
                                {wallet?.preferredUsername || ""}
                              </option>
                            ))}
                        </select>
                        <label>Please select wallet</label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row m-t-10">
                <div className="col-md-12 d-flex justify-content-end">
                  <button className="tbtc-btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <AlertModal
        message={alertMessage}
        isOpen={showAlertModal}
        handleClose={handleAlertModalClose}
      />
      <Web3ConnectModal
        isOpen={showWalletConnectionModal}
        handleClose={() => handleWalletConnectionModalClose()}
        handleMetamask={addMetamaskWallet}
        handleWalletConnect={addWalletConnectWallet}
      />
    </>
  );
});

export default ManageGoat;
