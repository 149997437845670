import React from "react";
import { useNavigate } from "react-router-dom";
import userStorage from "../../common/userStorage";
import logo from "../../images/logo.png";
import myImg from "../../images/user.png";
import "./Header.css";
import { Dropdown } from "react-bootstrap";

const Header = () => {
  const navigate = useNavigate();
  const userInfo = userStorage.getUserInfo();
  let pictureUrl = userInfo?.cognitoUser?.picture;
  if (!!pictureUrl) {
    pictureUrl = `${pictureUrl}?${Date.now()}`;
  }
  return (
    <div className="goat-gallery-header">
      <div className="logo pointer" onClick={() => navigate("/")}>
        <img src={logo} alt="" />
      </div>
      {/* <div className="user-details">
        <h5>
          {userInfo?.cognitoUser?.name} {userInfo?.cognitoUser?.familyName}
        </h5>
        <img src={pictureUrl || myImg} alt="" />
      </div> */}
      <Dropdown>
        <Dropdown.Toggle className="user-details" variant="">
          <h5>
            {userInfo?.cognitoUser?.name} {userInfo?.cognitoUser?.familyName}
          </h5>
          <img src={pictureUrl || myImg} alt="" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="tbtc-menu-link"
            href="#"
            onClick={(event: any) => {
              event.preventDefault();
              userStorage.revoveAllInfo();
              navigate("/");
              window.location.reload();
            }}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
