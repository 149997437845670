import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userStorage from "../../common/userStorage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ethereum from "../../images/ethereum.png";
import "./MiddleContent.css";

const MiddleContent = ({ numberOfGoat }: any) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const authInfo = userStorage.getAuthInfo();
  const userInfo = userStorage.getUserInfo();
  const [goatSetting, setGoatSetting] = useState<any>({});

  useEffect(() => {
    axiosPrivate.get("GetSetting").then((response) => {
      setGoatSetting(response?.data || {});
    });
  }, []);

  const logOut = async () => {
    userStorage.revoveAllInfo();
    navigate("/");
    window.location.reload();
  };

  return (
    <section className="middle-content overflow-hidden">
      <div className="middle-content-wrapper">
        <div className="row w-100">
          <div className="col-md-6">
            {!authInfo?.user ? (
              <button
                className="tbtc-btn m-r-10"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            ) : (
              <>
                {authInfo.isAdmin && (
                  <>
                    <button
                      className="tbtc-btn m-r-10"
                      onClick={() => navigate("/register")}
                    >
                      Add New Profile
                    </button>
                    <button
                      className="tbtc-btn m-r-10"
                      onClick={() => navigate("/setting")}
                    >
                      Setting
                    </button>
                  </>
                )}
                <button
                  className="tbtc-btn m-r-10"
                  onClick={() => navigate(`/update/${userInfo.id}`)}
                >
                  Update Profile
                </button>
                <button className="tbtc-btn m-r-10" onClick={logOut}>
                  Logout
                </button>
              </>
            )}
          </div>
          <div className="col-md-6">
            <div className="icons">
              <ul>
                <li>
                  <a href="https://www.beartiger.io/">
                    <img
                      className="first-icon"
                      src="https://i.ibb.co/R49r07k/globe-free-icon-font.png"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://discord.com/invite/GPCzabXh">
                    <img
                      src="https://i.ibb.co/rtkcYZg/discord-free-icon-font.png"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/accounts/login/?next=/thebeartigerclub/">
                    <img
                      src="https://i.ibb.co/dMq8s9z/new-Instagram-logo-white-glyph-1200x1199.png"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://opensea.io/tbtc">
                    <img
                      src="https://i.ibb.co/f2Djh1N/etherscan-logo-light-circle.png"
                      alt=""
                    />
                  </a>
                </li>

                <li>
                  <a href="https://twitter.com/beartigerclub?s=21&t=3SqQJojXydEVMfrXkpVLPQ">
                    <img
                      className="last-icon"
                      src="https://i.ibb.co/QQv8JfJ/twitter-free-icon-font.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="base-info">
          <div>
            <h4>Genesis GOAT Gallery</h4>
            <p>The Bear Tiger Club</p>
          </div>
        </div>
        <div className="member-price-main">
          <div className="member-price">
            <div className="item members">
              <strong>{numberOfGoat || 0}</strong>
              <p>Genesis Members</p>
            </div>
            <div className="item prices">
              <strong>
                <img src={ethereum} alt="" /> {goatSetting?.floorPrice || ""}
              </strong>
              <p>entry price</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MiddleContent;
