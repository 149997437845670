import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import defaultProfileImage from "../../images/default-profile.png";
// import Dropzone from "../dropzone/Dropzone";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ManageGoat from "./ManageGoat";
import { toast } from "react-toastify";
import userStorage from "../../common/userStorage";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../phoneInput/PhoneInput.css";
import "../register/Register.css";
import "./Updater.css";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import Web3ConnectModal from "../login/Web3ConnectModal";
import ConfirmationAlertModal from "../login/Modals/ConfirmationAlertModal";
import QRCode from "react-qr-code";
import web3Service from "../../common/web3Service";
import { isMobile } from "react-device-detect";
import OwnershipChecker from "./OwnershipChecker";
declare let window: any;

const WalletConnect = new WalletConnectConnector({
  // @ts-ignore
  rpcUrl: "https://rinkeby.infura.io/v3/9d097d80ee584f869bf6588285180249",
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

const Updater = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(defaultProfileImage);
  // const [nftImages, setNftImages] = useState<any[]>([]);
  const [profileImage, setProfileImage] = useState<any>(null);
  //For Dynamic Wallet Address Field
  const [isLoading, setIsLoading] = useState(false);
  // const [invalidImages, setInvalidImages] = useState<any>({});
  const axiosPrivate = useAxiosPrivate();
  // const [removedNftImages, setRemovedNftImages] = useState<any>([]);
  const [goatProfile, setGoatProfile] = useState<any>({});
  let { id } = useParams();
  // const [goatSetting, setGoatSetting] = useState<any>({});
  const [tokenUri, setTokenUri] = useState<any>("");
  // const [baseTokenUri, setBaseTokenUri] = useState<any>("");
  const [contractAddress, setContractAddress] = useState<any>("");
  const [wb3Wallets, setWeb3Wallets] = useState<any>([]);
  const [walletAddress, setWalletAddress] = useState<any>("");
  const [sign, setSign] = useState<any>("");
  const [verificaitonCode, setVerificaitonCode] = useState<any>("");
  const [MFAVerifactonCode, setMFAVerificaitonCode] = useState<any>("");
  // const [MFADeviceName, setMFADeviceName] = useState<any>("");
  const [ownerWallet, setOwnerWallet] = useState<any>("");
  const manageGoatRef = useRef<any>(null);
  const ownershipCheckRef = useRef<any>(null);
  const authInfo = userStorage.getAuthInfo();
  const userInfo = userStorage.getUserInfo();

  const [phoneNumber, setPhoneNumber] = useState<any>();

  const [isSignUp, setIsSignUp] = useState(true);

  const [disabledWallet, setDisabledWallet] = useState<any>();
  const [enabledWallet, setEnabledWallet] = useState<any>();
  const [TOTPSecret, setTOTPSecret] = useState("");

  const [showMFAModal, setShowMFAModal] = useState(false);
  const [visibleSecretKey, setVisibleSecretKey] = useState(false);
  const [MFAEnabled, setMFAEnabled] = useState(false);

  const handleMFAModalClose = () => {
    setShowMFAModal(false);
    setMFAEnabled(false);
  };
  const handleMFAModalShow = async (event: any) => {
    try {
      setMFAVerificaitonCode("");
      // setMFADeviceName("");
      setIsLoading(true);
      setMFAEnabled(event.target.checked);
      if (event.target.checked) {
        const secretResponse = await axiosPrivate.post("GenerateTOTPSecret");
        setTOTPSecret(secretResponse?.data);
        setShowMFAModal(true);
      } else {
        if (authInfo?.isAdmin) {
          await axiosPrivate.post("AdminDisableTOTPMFA", {
            cognitoUserId: goatProfile?.cognitoUser?.id,
          });
        } else {
          await axiosPrivate.post("DisableTOTPMFA");
        }
        toast.success("MFA Disabled!");
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.response?.data || "Failed!");
    }
  };

  const enabelMFA = async () => {
    if (!!MFAVerifactonCode) {
      try {
        setIsLoading(true);
        const secretResponse = await axiosPrivate.post("EnableTOTPMFA", {
          deviceName: "TBTC GOAT Gallery",
          userCode: MFAVerifactonCode,
        });
        setShowMFAModal(false);
        toast.success("MFA Enabled!");
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.response?.data || "Failed!");
      }
    }
  };
  const showHideMFASecretKey = (show: boolean) => {
    setVisibleSecretKey(show);
  };

  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  const handleVerificationCodeModalClose = () =>
    setShowVerificationCodeModal(false);
  const handleVerificationCodeModalShow = () => {
    setVerificaitonCode("");
    setShowVerificationCodeModal(true);
  };

  const [showRemoveWalletConfirmation, setShowRemoveWalletConfirmation] =
    useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => updateGoatProfile(data);

  const { account, activate, library } = useWeb3React();

  const [showWalletConnectionModal, setShowWalletConnectionModal] =
    useState(false);

  const handleWalletConnectionModalClose = () =>
    setShowWalletConnectionModal(false);
  const handleWalletConnectionModalShow = (event: any) => {
    event.preventDefault();
    setShowWalletConnectionModal(true);
  };

  const initializeAllWeb3Data = async (tokenId: any) => {
    const address = await getContractAddress();
    setContractAddress(address || "");
    // const baseUri = await getBaseTokenUri();
    // setBaseTokenUri(baseUri);
    const uri = await getTokenUri(tokenId);
    setTokenUri(uri || "");
    const wallet = await getOwnerWallet(tokenId);
    setOwnerWallet(wallet || "");
  };
  const getOwnerWallet = async (tokenId: any) => {
    if (tokenId == null) {
      return null;
    }
    const wallet = await web3Service.ownerOf(tokenId);
    return wallet;
  };

  const getTokenUri = async (tokenId: any = null) => {
    if (tokenId == null) {
      return null;
    }
    const tokenUri = await web3Service.tokenUri(tokenId);
    return tokenUri;
  };

  // const getBaseTokenUri = async () => {
  //   const tokenUri = await web3Service.baseTokenURI();
  //   return tokenUri;
  // };

  const getContractAddress = async () => {
    const tokenUri = await web3Service.getDefaultContractAddress();
    return tokenUri;
  };
  useEffect(() => {
    setIsLoading(true);
    try {
      axiosPrivate.get(`GetUserWithLinkedUsersById/${id}`).then(
        (response) => {
          setGoatProfile({ ...response?.data });
          let pictureUrl = response.data?.cognitoUser?.picture;
          if (!!pictureUrl) {
            pictureUrl = `${pictureUrl}?${Date.now()}`;
          }
          setProfilePicture(pictureUrl || defaultProfileImage);
          setMFAEnabled(
            response.data?.cognitoUser?.preferredMfaSetting ===
              "SOFTWARE_TOKEN_MFA"
          );
          // response?.data?.nftImage &&
          //   setNftImages([{ ...response?.data?.nftImage, id: uuid() }]);
          "id,isActive,whsIndex,isPublic,isGolfer,bio,irlMembershipCourses,lockerNumber,tokenId"
            .split(",")
            .forEach((prop) => {
              setValue(prop, response?.data[prop]);
            });
          "email,name,familyName".split(",").forEach((prop) => {
            setValue(prop, response?.data?.cognitoUser[prop]);
          });
          setPhoneNumber(response?.data?.cognitoUser?.phoneNumber || "");

          initializeAllWeb3Data(response?.data?.tokenId);
          // if (!!response?.data?.canTransferTokenId) {
          //   setValue("tokenId", null);
          // }

          // setValue(
          //   "walletAddresses",
          //   response?.data?.web3Wallets?.map((w: any) => w.address)
          // );

          const linkedUsers =
            (response?.data?.cognitoUser?.linkedUsers &&
              response?.data?.cognitoUser?.linkedUsers.filter(
                (u: any) => u.web3
              )) ||
            [];
          setWeb3Wallets([...(linkedUsers?.length > 0 ? linkedUsers : [])]);
          setValue(
            "isSuperAdmin",
            response?.data?.roles?.includes("SuperAdmin")
          );
          // axiosPrivate.get("GetSetting").then((response) => {

          //   setGoatSetting(response?.data || {});
          // });
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  // const addWalletAddress = async () => {
  //   if (!walletAddress) {
  //     return;
  //   }
  //   try {
  //     setIsLoading(true);
  //     const duplicateResponse = await axiosPrivate.post(
  //       "IsDuplicateWb3WalletAddress",
  //       { walletAddress: walletAddress }
  //     );
  //     if (duplicateResponse?.data) {
  //       setIsLoading(false);
  //       toast.error("Duplicate wallet!");
  //       return;
  //     }
  //     var response = await axiosPrivate.post("AddWallet", {
  //       walletAddress: walletAddress,
  //       signature: walletAddress,
  //       linkedUser: goatProfile.id,
  //       groups: ["GOAT"],
  //     });

  //     setWeb3Wallets((wallets: any) => {
  //       const all = [...wallets, response.data.cognitoUser];
  //       return [...all];
  //     });
  //     setIsLoading(false);
  //     setWalletAddress("");
  //     handleVerificationCodeModalClose();
  //     toast.success("Wallet added!");
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error("Failed!");
  //   }
  // };

  const enableWallet = async () => {
    if (!!verificaitonCode) {
      try {
        setIsLoading(true);
        var response = await axiosPrivate.post(
          "VerifyActivationCodeAndEnableUser",
          {
            cognitoUserId: disabledWallet?.id,
            walletAddress: walletAddress,
            signature: sign,
            code: verificaitonCode,
          }
        );

        setWeb3Wallets((wallets: any) => {
          const all = wallets.map((w: any) =>
            w.preferredUsername === walletAddress ? { ...w, enabled: true } : w
          );
          return [...all];
        });

        setGoatProfile((profile: any) => {
          const data = { ...profile };
          data.cognitoUser.linkedUsers = data.cognitoUser.linkedUsers.map(
            (w: any) =>
              w.preferredUsername === walletAddress
                ? { ...w, enabled: true }
                : w
          );
          return { ...data };
        });

        setIsLoading(false);
        handleVerificationCodeModalClose();
        toast.success("Successfully enabled!");
      } catch (err: any) {
        setIsLoading(false);
        toast.error(err?.response?.data || "Failed!");
        //handleVerificationCodeModalClose();
      }
    }
  };
  const sendVerificationCode = async () => {
    try {
      setIsLoading(true);
      var response = await axiosPrivate.post(
        "SendActivationCodeByWalletAddress",
        { walletAddress: disabledWallet.preferredUsername }
      );
      setIsLoading(false);
      toast.success("Email verification code has sent!");
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.response?.data || "Failed!");
    }
  };
  const verifyWeb3Wallet = async (address: any, signatureHash: any) => {
    if (
      address.toLowerCase() === disabledWallet.preferredUsername.toLowerCase()
    ) {
      await sendVerificationCode();
      setWalletAddress(address);
      setSign(signatureHash);

      handleVerificationCodeModalShow();
    } else {
      toast.error("Wallets are not matched!");
    }
  };
  const sendInvitationMail = async (event: any) => {
    try {
      event?.preventDefault();
      setIsLoading(true);
      await axiosPrivate.post(`SendWelcomeMail/${goatProfile.id}`);
      setIsLoading(false);
      toast.success("Email has been sent successfully!");
    } catch (e) {
      setIsLoading(false);
      toast.error("Failed!");
      console.error(e);
    }
  };

  const addWalletConnectWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    // disconnect();
    // setIsLoading(true);
    await activate(WalletConnect);
  };
  useEffect(() => {
    if (!!account) {
      signMessage();
    }
  }, [account]);
  const signMessage = async () => {
    const message = `This request will not trigger a blockchain transaction or cost any gas fees.\nWallet address:\n${account}`;
    try {
      setIsLoading(true);
      // @ts-ignore
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account],
      });
      const sign = signature?.slice(-162);
      if (!!sign) {
        if (isSignUp) {
          await addWeb3Wallet(account, sign);
        } else {
          verifyWeb3Wallet(account, sign);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const addMetamaskWallet = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    if (window.ethereum && window.ethereum.isMetaMask) {
      let accounts = [];
      if (isMobile) {
        accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } else {
        accounts = await window.ethereum
          .request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(() =>
            window.ethereum.request({
              method: "eth_requestAccounts",
            })
          );
      }
      if (!!accounts) {
        const address = accounts[0];
        const message = `This request will not trigger a blockchain transaction or cost any gas fees.\nWallet address:\n${address}`;

        const signatureHash = await window.ethereum.request({
          method: "personal_sign",
          params: [message, address],
        });
        if (isSignUp) {
          await addWeb3Wallet(address, signatureHash);
        } else {
          verifyWeb3Wallet(address, signatureHash);
        }
      }
    }
  };
  // Wallet address field added handler
  const addWeb3Wallet = async (address: any, signatureHash: any) => {
    let api = "SignUpWeb3User";
    if (
      !wb3Wallets ||
      !wb3Wallets.find((w: any) => w.walletAddress === address)
    ) {
      try {
        setIsLoading(true);
        const duplicateResponse = await axiosPrivate.post(
          "IsDuplicateWb3WalletAddress",
          { walletAddress: address }
        );
        if (duplicateResponse?.data) {
          // const userResponse = await axiosPrivate.get(
          //   `GetUserByWalletAddress/${address}`
          // );
          // if (!userResponse?.data?.enabled) {
          //   toast.error("Wallet is disabled!");
          //   setIsLoading(false);
          //   return;
          // } else {
          if (
            window.confirm(
              "The Wallet is already existis in the system. Do you want to add with your primary account?"
            )
          ) {
            api = "SignUpExistingWeb3User";
          } else {
            setIsLoading(false);
            return;
          }
          // }
        }

        var response = await axiosPrivate.post(api, {
          walletAddress: address,
          signature: signatureHash,
          linkedUser: goatProfile.id,
          groups: ["GOAT"],
        });

        const walletData = response.data;

        const mailResponse = await axiosPrivate.post("SendMailToAdmin", {
          subject: "New wallet added",
          body: `Email: ${goatProfile?.cognitoUser?.email}\nName: ${
            goatProfile?.cognitoUser?.name
          } ${goatProfile?.cognitoUser?.familyName}\nLOCKER #: ${
            goatProfile?.lockerNumber || ""
          }\nToken ID: ${goatProfile?.tokenId} || ""\nWallet Address: ${
            walletData?.preferredUsername
          }`,
        });

        setWeb3Wallets((wallets: any) => {
          const all = [...wallets, walletData];
          return [...all];
        });

        setGoatProfile((profile: any) => {
          const data = { ...profile };
          data.cognitoUser.linkedUsers = [
            ...data.cognitoUser.linkedUsers,
            walletData,
          ];
          return { ...data };
        });

        setIsLoading(false);
        toast.success("Wallet added!");
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed!");
      }
    } else {
      toast.error("Duplicate wallet!");
      return;
    }
  };
  // Wallet address field remove handler
  const disableWeb3Wallet = async (wallet: any) => {
    if (wallet?.walletAddress === goatProfile?.cognitoUser?.preferredUsername) {
      toast.error("Not allowed!");
      return;
    }
    // if (window.confirm("Are you sure?")) {
    const address = wallet?.preferredUsername;
    if (!!address) {
      try {
        setIsLoading(true);
        var response = await axiosPrivate.post(
          `DisableUserByCognitoUserId/${wallet.id}`
        );
        //await refreshUserData();
        setWeb3Wallets((wallets: any) => {
          const all = [...wallets];
          all.map((w: any) => {
            if (w.id === wallet.id) {
              w.enabled = false;
            }
            return w;
          });
          return [...all];
        });

        setGoatProfile((profile: any) => {
          const data = { ...profile };
          data.cognitoUser.linkedUsers = data.cognitoUser.linkedUsers.map(
            (w: any) => {
              if (w.id === wallet.id) {
                w.enabled = false;
              }
              return w;
            }
          );
          return { ...data };
        });

        // setWeb3Wallets((wallets: any) => {
        //   let remaining = wallets.filter((w: any) => w.id !== wallet.id);
        //   if (!remaining?.length || remaining?.length === 0) {
        //     remaining = [{ id: uuid() }];
        //   }
        //   return [...remaining];
        // });

        setIsLoading(false);
        toast.success("Wallet disabled!");
      } catch (error) {
        setIsLoading(false);
        toast.error("Failed!");
      }
    }
    // }
  };

  // const handleNftImageUpload = (file: any) => {
  //   setNftImages((nfts) => [
  //     ...nfts,
  //     {
  //       id: uuid(),
  //       isDefault: nfts.length === 0 ? true : false,
  //       image: file,
  //     },
  //   ]);
  // };

  // const removeNftImage = (nftImage: any) => {
  //   if (nftImage?.accessUrl) {
  //     var removedImages = [...removedNftImages];
  //     removedImages.push(nftImage);
  //     setRemovedNftImages([...removedImages]);
  //   }
  //   setNftImages(nftImages.filter((nft) => nft.id !== nftImage.id));
  // };

  // const handleLockerNumberChange = (event: any, nftImage: any) => {
  //   const value = event.target.value;
  //   // event.target.classList.toggle("bg-salmon");
  //   setNftImages(
  //     nftImages?.map((nft) =>
  //       nft.id === nftImage.id ? { ...nft, lockerNumber: value } : nft
  //     )
  //   );
  // };
  // const handleIsDefaultChange = (
  //   event: any,
  //   isDefault: boolean,
  //   nftImage: any
  // ) => {
  //   setNftImages(
  //     nftImages?.map((nft) =>
  //       nft.id === nftImage.id
  //         ? { ...nft, isDefault: isDefault }
  //         : { ...nft, isDefault: false }
  //     )
  //   );
  // };
  // const validateNftImages = () => {
  //   const images = nftImages.filter((nft) => !nft.lockerNumber);
  //   if (images.length > 0) {
  //     const imgs: any = {};
  //     images.forEach((img) => {
  //       imgs[img.id] = true;
  //     });
  //     setInvalidImages(imgs);
  //     return false;
  //   } else {
  //     setInvalidImages({});
  //   }
  //   return true;
  // };
  const updateGoatProfile = async (profile: any) => {
    try {
      if (
        profile.tokenId != null &&
        profile.tokenId !== undefined &&
        profile.tokenId !== "" &&
        profile.tokenId != goatProfile.tokenId
      ) {
        setIsLoading(true);
        var res = await axiosPrivate.post("IsDuplicateTokenId", {
          tokenId: profile.tokenId,
          userId: profile.id,
        });
        setIsLoading(false);
        if (!!res.data) {
          toast.error("Duplicate Token Id");
          setError("tokenId", { type: "focus" }, { shouldFocus: true });
          return;
        }
      }
      if (
        !!profile.lockerNumber &&
        profile.lockerNumber !== goatProfile.lockerNumber
      ) {
        setIsLoading(true);
        const duplicateLockerNumberResult = await axiosPrivate.post(
          "IsDuplicateLockerNumber",
          {
            lockerNumber: profile.lockerNumber,
            userId: profile.id,
          }
        );
        setIsLoading(false);
        if (!!duplicateLockerNumberResult.data) {
          toast.error("Duplicate Locker#");
          setError("lockerNumber", { type: "focus" }, { shouldFocus: true });
          return;
        }
      }

      setIsLoading(true);
      const formDataRequestHeader = {
        headers: {
          "Content-type": "multipart/form-data",
        },
      };
      profile.tokenId =
        profile.tokenId != null &&
        profile.tokenId !== undefined &&
        profile.tokenId !== ""
          ? parseInt(profile.tokenId)
          : null;
      const response = await axiosPrivate.put(`UpdateUser/${profile.id}`, {
        ...profile,
        phoneNumber: phoneNumber,
        // tokenUri: baseTokenUri,
      });

      if (!!profileImage) {
        const formData = new FormData();
        formData.append("image", profileImage);
        formData.append("userId", goatProfile.id);
        const uploadResponse = await axiosPrivate.post(
          "UploadProfilePicture",
          formData,
          formDataRequestHeader
        );
      }
      await updateUserProfile();

      setIsLoading(false);
      toast.success("Profile updated!");
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      toast.error(err?.response?.data ?? "Failed!");
    }
  };
  const updateUserProfile = async () => {
    const userResponse = await axiosPrivate.get("GetUserProfile");
    userResponse?.data && userStorage.saveUserInfo(userResponse?.data);
    await initializeAllWeb3Data(userResponse?.data?.tokenId);
  };
  const handleProfilePictureUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!!selectedFile) {
      setProfilePicture(URL.createObjectURL(selectedFile));
      setProfileImage(selectedFile);
    }
  };

  // const addTokenId = async (event: any) => {
  //   event.preventDefault();
  //   const tokenId = getValues("tokenId");
  //   if (tokenId == null) {
  //     toast.error("TokenId is empty!");
  //     return;
  //   }
  //   try {
  //     var res = await axiosPrivate.post("IsDuplicateTokenId", {
  //       tokenId: tokenId,
  //       userId: goatProfile.id,
  //     });
  //     if (!!res.data) {
  //       toast.error("Duplicate Token Id");
  //       return;
  //     }
  //     setIsLoading(true);
  //     const response = await axiosPrivate.post("AddTokenId", {
  //       id: goatProfile.id,
  //       tokenId: tokenId,
  //       tokenUri: baseTokenUri,
  //     });
  //     goatProfile.tokenId = response?.data;
  //     await initializeAllWeb3Data(response?.data);
  //     toast.success("Token id added successfully!");
  //     setIsLoading(false);
  //   } catch (err: any) {
  //     setIsLoading(false);
  //     console.error(err?.response?.data);
  //     toast.error("Failed!");
  //   }
  // };
  //Progressbar Value
  // const now = 100;

  return (
    <>
      <div className={isLoading ? "disabled register-main" : "register-main"}>
        <Header />
        <div className="tbtc-form-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="tbtc-form">
            <h3 className="header-text">Update Profile</h3>
            <div className="row">
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    disabled
                    {...register("email", {
                      required: true,
                    })}
                    aria-invalid={errors.email ? "true" : "false"}
                    className={errors.email ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>Email</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("name", {
                      required: true,
                    })}
                    aria-invalid={errors.name ? "true" : "false"}
                    className={errors.name ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>NAME</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("familyName", {
                      required: true,
                    })}
                    aria-invalid={errors.familyName ? "true" : "false"}
                    className={errors.familyName ? "invalid-field" : ""}
                  />
                  <div className="required-star">*</div>
                  <label>FAMILY NAME</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    disabled={!authInfo?.isAdmin}
                    {...register("lockerNumber", {
                      minLength: 4,
                      validate: {
                        lockerNumberValidation: (val: string) => {
                          return !val || /^\d+$/.test(val);
                        },
                      },
                    })}
                    aria-invalid={errors.lockerNumber ? "true" : "false"}
                    className={errors.lockerNumber ? "invalid-field" : ""}
                  />
                  <label>LOCKER #</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="number"
                    disabled={!authInfo?.isAdmin}
                    {...register("tokenId", {
                      // required: true,
                      // validate: {
                      //   tokenIdValidation: (val: string) => {
                      //     return !nftImages?.length || nftImages?.length === 0
                      //       ? true
                      //       : nftImages?.length > 0 && !!val;
                      //   },
                      // },
                    })}
                    aria-invalid={errors.tokenId ? "true" : "false"}
                    className={errors.tokenId ? "invalid-field" : ""}
                  />
                  <label>Token Id</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    type="text"
                    value={contractAddress || ""}
                    name="contractAddress"
                    disabled
                  />
                  <label>Contract Address</label>
                  <span
                    className="copy-button"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(contractAddress);
                    }}
                  >
                    Copy
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <input
                    type="text"
                    value={tokenUri || ""}
                    name="tokenUri"
                    disabled
                  />
                  <label>Token URI</label>
                  <span
                    className="copy-button"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(tokenUri);
                    }}
                  >
                    Copy
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <input
                    type="text"
                    value={ownerWallet || ""}
                    name="ownerWallet"
                    disabled
                  />
                  <label>Owner Wallet</label>
                  <span
                    className="copy-button"
                    onClick={(event: any) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(ownerWallet);
                    }}
                  >
                    Copy
                  </span>
                </div>
              </div>
            </div>
            {authInfo.isAdmin ? (
              <div className="row h-100 m-t-10">
                <div className="col-md-3 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input type="checkbox" {...register("isPublic")} />
                    <label>Is Public</label>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input type="checkbox" {...register("isSuperAdmin")} />
                    <label>Super Admin</label>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input
                      disabled={
                        (userInfo.id !== goatProfile.id && !MFAEnabled) ||
                        !!authInfo?.web3 ||
                        !!goatProfile?.cognitoUser?.web3
                      }
                      type="checkbox"
                      checked={MFAEnabled}
                      onChange={handleMFAModalShow}
                    />
                    <label>
                      MFA (Multi-factor authentication)
                      <br />
                      Must be logged in using Email
                    </label>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input type="checkbox" {...register("isGolfer")} />
                    <label>Is IRL Golfer</label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row h-100 m-t-10">
                <div className="col-md-6 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input
                      type="checkbox"
                      disabled={
                        !(userInfo.id === goatProfile.id) || !!authInfo?.web3
                      }
                      checked={MFAEnabled}
                      onChange={handleMFAModalShow}
                    />
                    <label>MFA (Multi-factor authentication)</label>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="tbtc-checkbox">
                    <input type="checkbox" {...register("isGolfer")} />
                    <label>Is IRL Golfer</label>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <div className="phone-number goat-phone-number">
                  <PhoneInput
                    country="gb"
                    placeholder=""
                    value={phoneNumber}
                    enableSearch={true}
                    onChange={(num: any) => {
                      setPhoneNumber(num && `+${num}`);
                      // console.log(num && `+${num}`);
                    }}
                  />
                  <label className="phone-number-label">PHONE NUMBER</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tbtc-form-group">
                  <input
                    {...register("whsIndex")}
                    aria-invalid={errors.whsIndex ? "true" : "false"}
                    className={errors.whsIndex ? "invalid-field" : ""}
                  />
                  <label>WHS INDEX</label>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <div className="img-picker">
                  <div className="input--file">
                    <div>
                      <img
                        src={profilePicture}
                        alt=""
                        className="rounded-circle profile-picture"
                        width={40}
                        height={40}
                      />
                    </div>
                    <input
                      name="Select File"
                      type="file"
                      onChange={handleProfilePictureUpload}
                    />
                  </div>
                  <span className="all-img-heading">
                    Update your profile pic so others know who you are.
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <textarea
                    {...register("bio")}
                    aria-invalid={errors.bio ? "true" : "false"}
                    className={errors.bio ? "invalid-field" : ""}
                  />
                  <label>BIO</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="tbtc-form-group">
                  <textarea
                    {...register("irlMembershipCourses")}
                    aria-invalid={
                      errors.IrlMembershipCourses ? "true" : "false"
                    }
                    className={
                      errors.IrlMembershipCourses ? "invalid-field" : ""
                    }
                  />
                  <label>IRL MEMBERSHIP COURSES</label>
                </div>
              </div>
            </div>
            {userInfo.id === goatProfile.id && (
              <div className="row m-t-5">
                <div className="col-md-12">
                  <button
                    className="tbtc-btn"
                    onClick={(event) => {
                      setIsSignUp(true);
                      handleWalletConnectionModalShow(event);
                    }}
                  >
                    Add Wallet
                  </button>
                </div>
              </div>
            )}
            {wb3Wallets.length ? (
              <>
                {wb3Wallets?.map((wallet: any) => (
                  <div key={uuid()} className="row">
                    <div className="col-md-6 d-flex">
                      <div className="d-flex flex-grow-1">
                        <div className="tbtc-form-group">
                          <input
                            value={wallet?.preferredUsername || ""}
                            disabled={true}
                          />
                          <label>WALLET ADDRESS</label>
                          <span
                            className="copy-button"
                            onClick={(event: any) => {
                              event.preventDefault();
                              navigator.clipboard.writeText(
                                wallet?.preferredUsername
                              );
                            }}
                          >
                            Copy
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {!wallet?.enabled ? (
                          <button
                            disabled={!(userInfo.id === goatProfile.id)}
                            className="tbtc-btn wallet-control-btn m-t-10 m-l-10"
                            onClick={(event) => {
                              event.preventDefault();
                              setDisabledWallet(wallet);
                              setIsSignUp(false);
                              handleWalletConnectionModalShow(event);
                            }}
                          >
                            Enable
                          </button>
                        ) : (
                          <button
                            className="tbtc-btn wallet-control-btn wallet-disable-btn danger-btn m-t-10 m-l-10"
                            onClick={(event) => {
                              event.preventDefault();
                              setEnabledWallet(wallet);
                              setShowRemoveWalletConfirmation(true);
                            }}
                          >
                            Disable
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        className="wallet-address-input"
                        value={""}
                        disabled={true}
                      />
                      <label>WALLET ADDRESS</label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row m-t-10 d-md-none d-lg-none d-xl-none">
              <div className="col-sm-12 d-flex align-items-center justify-content-end">
                {authInfo?.isAdmin && (
                  <button className="tbtc-btn" onClick={sendInvitationMail}>
                    Send Welcome Email
                  </button>
                )}
              </div>
            </div>
            <div className="row m-t-10">
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                {authInfo?.isAdmin && (
                  <button
                    className="tbtc-btn m-r-10 d-none d-md-block d-lg-block d-xl-block"
                    onClick={sendInvitationMail}
                  >
                    Send Welcome Email
                  </button>
                )}
                <button
                  className="tbtc-btn m-r-10"
                  onClick={(event: any) => {
                    event.preventDefault();
                    navigate("/");
                  }}
                >
                  View Gallery
                </button>
                {(userInfo.id === goatProfile.id || authInfo?.isAdmin) && (
                  <>
                    <button
                      className="tbtc-btn m-r-10"
                      onClick={async (event: any) => {
                        event.preventDefault();
                        await ownershipCheckRef.current.showOwnershipCheckModal(
                          goatProfile
                        );
                      }}
                    >
                      Check Ownership
                    </button>
                    <button
                      className="tbtc-btn m-r-10"
                      onClick={(event: any) => {
                        event.preventDefault();
                        manageGoatRef.current.showManageGoatModal(goatProfile);
                      }}
                    >
                      Manage GOAT
                    </button>
                  </>
                )}
                <button className="tbtc-btn" type="submit">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <ManageGoat ref={manageGoatRef} />
      <OwnershipChecker ref={ownershipCheckRef} />

      <Modal
        show={showVerificationCodeModal}
        onHide={handleVerificationCodeModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enable Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Varification Code"
              value={verificaitonCode}
              onChange={(event) => setVerificaitonCode(event.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="resent-activation-code-link"
            href="/"
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              sendVerificationCode();
            }}
          >
            Resend Verification Code
          </a>
          <button
            className="tbtc-btn"
            onClick={(event: any) => {
              event.preventDefault();
              enableWallet();
            }}
          >
            Enable
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMFAModal} onHide={handleMFAModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>MFA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-b-10">
            <div className="col-md-7">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 200,
                  width: "100%",
                  padding: "10px",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`otpauth://totp/${encodeURIComponent(
                    //(goatProfile?.cognitoUser?.email || "") +
                    `${window.location.host} [${goatProfile?.cognitoUser?.name} ${goatProfile?.cognitoUser?.familyName}]`
                  )}?secret=${TOTPSecret}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            <div className="col-md-5">
              <p>
                Scan this QR code with your authenticator app. Alternately, you
                can manually enter a secret key in your authentication app.
              </p>
              {!visibleSecretKey ? (
                <a
                  className="resent-activation-code-link"
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    showHideMFASecretKey(true);
                  }}
                >
                  Show secret key
                </a>
              ) : (
                <a
                  className="resent-activation-code-link"
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    showHideMFASecretKey(false);
                  }}
                >
                  Hide secret key
                </a>
              )}
            </div>
          </div>
          {visibleSecretKey && (
            <div>
              <pre className="fs-12">{TOTPSecret}</pre>
            </div>
          )}
          <div className="form-group m-b-5">
            <input
              type="text"
              className="form-control"
              placeholder="MFA verification Code"
              value={MFAVerifactonCode}
              onChange={(event) => setMFAVerificaitonCode(event.target.value)}
            />
          </div>
          {/* <div className="form-group m-b-5">
            <input
              type="text"
              className="form-control"
              placeholder="Friendly device name"
              value={MFADeviceName}
              onChange={(event) => setMFADeviceName(event.target.value)}
            />
          </div> */}
          <div className="d-flex justify-content-end">
            <button
              className="tbtc-btn"
              disabled={isLoading}
              onClick={(event: any) => {
                event.preventDefault();
                enabelMFA();
              }}
            >
              Enable
            </button>
            {/* <Button
              className="tbtc-btn"
              variant="primary"
              onClick={(event: any) => {
                event.preventDefault();
                enabelMFA();
              }}
            >
              Enable
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>

      {showRemoveWalletConfirmation && (
        <ConfirmationAlertModal
          message={
            "This will disable the wallet, you will need an email address associated to your account to re ENABLE, do you wish to continue?"
          }
          handleCancel={() => {
            setShowRemoveWalletConfirmation(false);
          }}
          handleOk={() => {
            disableWeb3Wallet(enabledWallet);
            setShowRemoveWalletConfirmation(false);
          }}
        />
      )}
      <Web3ConnectModal
        isOpen={showWalletConnectionModal}
        handleClose={() => handleWalletConnectionModalClose()}
        handleMetamask={addMetamaskWallet}
        handleWalletConnect={addWalletConnectWallet}
      />
    </>
  );
};

export default Updater;
