import { useCallback } from "react";
import styled from "styled-components";
import WhiteModal from "./Modals/WhiteModal";
import { Heading6, Body2 } from "./Typography";

const ConnectorsContainer = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
`;

const Connector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 1 0;
  width: 0;
  cursor: pointer;
`;

const ConnectorName = styled.div`
  ${Heading6}
  text-align: center;
  @media (max-width: 576px) {
    ${Body2}
  }
`;

const ConnectorLogo = styled.img`
  width: 60%;

  @media (max-width: 456px) {
    width: 100%;
  }
`;

type Web3ConnectModalProps = {
  isOpen: boolean;
  handleClose?: () => void;
};

export default function Web3ConnectModal({
  isOpen,
  handleClose,
  handleMetamask,
  handleWalletConnect,
}: any) {
  const handleConnectorClick = useCallback(
    async (isMetamask: boolean) => {
      if (isMetamask) {
        handleMetamask();
      } else {
        handleWalletConnect();
      }
      if (handleClose) {
        handleClose();
      }
    },
    [handleClose]
  );

  return (
    <WhiteModal
      title="Connect Your Wallet"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <ConnectorsContainer>
        <Connector onClick={() => handleConnectorClick(true)}>
          <ConnectorLogo
            src="https://tbtc-user-dev.s3.us-west-1.amazonaws.com/images/logo-metamask.png"
            alt="connector"
          />
          <ConnectorName>Metamask</ConnectorName>
        </Connector>
        <Connector onClick={() => handleConnectorClick(false)}>
          <ConnectorLogo
            src="https://tbtc-user-dev.s3.us-west-1.amazonaws.com/images/logo-walletconnect.png"
            alt="walletconnect"
          />
          <ConnectorName>Walletconnect</ConnectorName>
        </Connector>
      </ConnectorsContainer>
    </WhiteModal>
  );
}
