import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../header/Header";
// import axios from "axios";
import "./GoatSetting.css";
import web3Service from "../../common/web3Service";
// const {
//   REACT_APP_ETHERSCAN_API_KEY,
//   REACT_APP_ETHERSCAN_API_BASE_URL,
//   REACT_APP_ETHEREUM_NODE_URL,
// } = process.env;
// declare let window: any;
// const Web3 = require("web3");
// const web3 = new Web3(
//   new Web3.providers.HttpProvider(REACT_APP_ETHEREUM_NODE_URL)
// );
const GoatSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [contractABI, setContractABI] = useState<any>(null);
  const [isValidContractAddress, setIsValidContractAddress] = useState(true);
  // const [attributes, setAttrbutes] = useState<any>([
  //   { id: uuid(), trait_type: "", value: "" },
  // ]);
  const axiosPrivate = useAxiosPrivate();
  // const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      setIsLoading(true);
      axiosPrivate.get("GetSetting").then(
        (response) => {
          if (!!response?.data) {
            "id,tokenUri,contractAddress,ownerWalletAddress,floorPrice,sigAppApi"
              .split(",")
              .forEach((prop) => {
                setValue(prop, response?.data[prop]);
              });
            setValue(
              "nftJsonSettings",
              JSON.stringify(response?.data?.nftJsonSettings, null, "\t")
            );
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const onSubmit = (data: any) => saveGoatSetting(data);

  const saveGoatSetting = async (data: any) => {
    if (!isValidContractAddress) {
      setError("contractAddress", { type: "focus" }, { shouldFocus: true });
      return;
    }
    data.nftJsonSettings = JSON.parse(data.nftJsonSettings);
    setIsLoading(true);
    try {
      await axiosPrivate.post("SaveSetting", data);
      setIsLoading(false);
      toast.success("Setting updated succefully!");
      web3Service.clearCache();
      // navigate("/");
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed!");
    }
  };

  const loadEtherData = async (event: any) => {
    event.preventDefault();
    const address = getValues("contractAddress");
    if (!address) {
      toast.error("Please insert a contract address!");
      return;
    }
    try {
      // const account = await walletService.connectAndGetWallet();
      // if (!account) {
      //   toast.error("Failed!");
      //   return;
      // }
      setIsLoading(true);
      // let abi = contractABI;
      // if (!contractABI) {
      //   const response = await axios.get(
      //     `${REACT_APP_ETHERSCAN_API_BASE_URL}?module=contract&action=getabi&address=${address}&apikey=${REACT_APP_ETHERSCAN_API_KEY}`
      //   );
      //   if (response?.data?.status === "0") {
      //     toast.error(response?.data?.result);
      //     setIsLoading(false);
      //     setError("contractAddress", { type: "focus" }, { shouldFocus: true });
      //     setIsValidContractAddress(false);
      //     return;
      //   }
      //   abi = JSON.parse(response?.data?.result || "");
      //   setContractABI(abi);
      //   setIsValidContractAddress(true);
      // }
      // const contract = new web3.eth.Contract(abi, address);
      // const owner = await contract.methods.owner().call();
      // const tokenURI = await contract.methods.baseTokenURI().call();
      const owner = await web3Service.owner(address);
      const tokenURI = await web3Service.baseTokenURI(address);
      setValue("tokenUri", tokenURI);
      setValue("ownerWalletAddress", owner);
      setIsLoading(false);
      toast.success("Synced data succefully!");
    } catch (error) {
      setIsValidContractAddress(false);
      setError("contractAddress", { type: "focus" }, { shouldFocus: true });
      console.error(error);
      setIsLoading(false);
      toast.error("Failed!");
    }
  };

  // const addAtributeUi = (event: any) => {
  //   event.preventDefault();
  //   let allattributes = [...attributes];
  //   const index = allattributes?.length || 0;
  //   allattributes.push({ id: index, trait_type: "", value: "" });
  //   setAttrbutes([...allattributes]);
  // };

  // const deleteAttribute = (event: any, id: any) => {
  //   event.preventDefault();
  //   let allattributes = [...attributes].filter((a) => a.id !== id);
  //   setAttrbutes([...allattributes]);
  //   const attrs = [...(getValues("nftJsonSettings.attributes") || [])];
  //   const allAttrs = [...attrs].filter((a, index) => index !== id);
  //   setValue("nftJsonSettings.attributes", [...(allAttrs || [])]);
  // };

  return (
    <>
      <div className={isLoading ? "disabled" : ""}>
        <Header />
        <div className="tbtc-form-wrapper">
          <div className="row">
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-12">
              <form className="tbtc-form" onSubmit={handleSubmit(onSubmit)}>
                <h3 className="header-text">Goat Settings</h3>
                <div className="row">
                  <div className="form-group col-md-6">
                    <div className="">
                      <div className="tbtc-form-group tbtc-search-group">
                        <input
                          type="text"
                          {...register("contractAddress", {
                            onChange: (e) => {
                              setValue("ownerWalletAddress", "");
                              setValue("tokenUri", "");
                              // setContractABI(null);
                            },
                          })}
                          aria-invalid={
                            errors.contractAddress ? "true" : "false"
                          }
                          className={
                            errors.contractAddress
                              ? "invalid-field form-control contract-address-input"
                              : "form-control contract-address-input"
                          }
                        />
                        <label>Contract Address</label>
                        <span
                          className="copy-button"
                          onClick={(event: any) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(
                              getValues("contractAddress")
                            );
                          }}
                        >
                          Copy
                        </span>
                        <i
                          className="fas fa-search search-icon contract-address-search"
                          onClick={loadEtherData}
                        ></i>
                      </div>

                      {/* <div className="input-group-append">
                        <button
                          className="tbtc-btn"
                          type="button"
                          onClick={loadEtherData}
                        >
                          Search
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        type="text"
                        {...register("ownerWalletAddress")}
                        readOnly
                        aria-invalid={
                          errors.ownerWalletAddress ? "true" : "false"
                        }
                        className={
                          errors.ownerWalletAddress
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Owner Wallet</label>
                      <span
                        className="copy-button"
                        onClick={(event: any) => {
                          event.preventDefault();
                          navigator.clipboard.writeText(
                            getValues("ownerWalletAddress")
                          );
                        }}
                      >
                        Copy
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        type="text"
                        {...register("tokenUri")}
                        aria-invalid={errors.tokenUri ? "true" : "false"}
                        readOnly
                        className={
                          errors.tokenUri
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Token URI</label>
                      <span
                        className="copy-button"
                        onClick={(event: any) => {
                          event.preventDefault();
                          navigator.clipboard.writeText(getValues("tokenUri"));
                        }}
                      >
                        Copy
                      </span>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        type="text"
                        {...register("floorPrice")}
                        aria-invalid={errors.floorPrice ? "true" : "false"}
                        className={
                          errors.floorPrice
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Floor Price</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="tbtc-form-group">
                      <input
                        type="text"
                        {...register("sigAppApi")}
                        aria-invalid={errors.sigAppApi ? "true" : "false"}
                        className={
                          errors.sigAppApi
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Sig App API</label>
                      <span
                        className="copy-button"
                        onClick={(event: any) => {
                          event.preventDefault();
                          navigator.clipboard.writeText(getValues("sigAppApi"));
                        }}
                      >
                        Copy
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tbtc-form-group">
                      <textarea
                        {...register("nftJsonSettings")}
                        aria-invalid={errors.nftJson ? "true" : "false"}
                        className={errors.nftJson ? "invalid-field" : ""}
                      />
                      <label>NFT Json Settings</label>
                      <span
                        className="copy-button"
                        onClick={(event: any) => {
                          event.preventDefault();
                          navigator.clipboard.writeText(
                            getValues("nftJsonSettings")
                          );
                        }}
                      >
                        Copy
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        type="text"
                        {...register("nftJsonSettings.description")}
                        aria-invalid={errors.description ? "true" : "false"}
                        className={
                          errors.description
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Description</label>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <div className="tbtc-form-group">
                      <input
                        type="number"
                        {...register("nftJsonSettings.edition")}
                        aria-invalid={errors.edition ? "true" : "false"}
                        className={
                          errors.edition
                            ? "invalid-field form-control"
                            : "form-control"
                        }
                      />
                      <label>Edition</label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-md-12">
                    <label>Attributes</label>{" "}
                    <button className="tbtc-btn" onClick={addAtributeUi}>
                      Add New
                    </button>
                  </div>
                </div> */}
                {/* {attributes &&
                  attributes.map((attribute: any, index: any) => (
                    <div key={attribute.id} className="m-t-10">
                      <div className="input-group attribute-group">
                        <span className="input-group-text">trait_type</span>
                        <input
                          type="text"
                          {...register(
                            `nftJsonSettings.attributes[${index}].trait_type`
                          )}
                          className="form-control"
                        />
                        <span className="input-group-text">value</span>
                        <input
                          type="text"
                          {...register(
                            `nftJsonSettings.attributes[${index}].value`
                          )}
                          className="form-control"
                        />
                        <i
                          className="fa fa-trash attribut-remove-btn"
                          aria-hidden="true"
                          onClick={(event) => {
                            deleteAttribute(event, attribute.id);
                          }}
                        ></i>
                      </div>
                    </div>
                  ))} */}
                <div className="row m-t-5">
                  <div className="col-md-12 d-flex justify-content-end">
                    <button className="tbtc-btn" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="goat-spinner">
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};
export default GoatSetting;
